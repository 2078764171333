import { ref, onMounted } from 'vue';
import { getDevices } from '@/services/devices';

export function useDevices() {
  const devicesList = ref<any[]>([]);
  const totalDevicePages = ref<number>(0);
  const currentDevicePage = ref<number>(1);
  const isDevicesLoading = ref<boolean>(false);

  const fetchDeviceList = async (page = 1, searchTerm = '') => {
    isDevicesLoading.value = true;
    try {
      const { totalPages, filteredRecord } = await getDevices({ page, search: searchTerm });
      totalDevicePages.value = totalPages;
      currentDevicePage.value = page;

      return filteredRecord.map((device) => {
        return {
          device_uuid: device.device_uuid,
          device_id: device.id,
          operational_number: device.operational_number,
        };
      });
    } catch (error) {
      console.error('Failed to load devices:', error);
      return [];
    } finally {
      isDevicesLoading.value = false;
    }
  };

  const loadMoreDevices = async ({ done }) => {
    if (currentDevicePage.value < totalDevicePages.value) {
      const newDevices = await fetchDeviceList(currentDevicePage.value + 1);

      newDevices.forEach((newDevice) => {
        const exists = devicesList.value.some((device) => device.device_id === newDevice.device_id);
        if (!exists) {
          devicesList.value.push(newDevice);
        }
      });
    }
    done();
  };

  const onDeviceSearch = async (searchTerm: string) => {
    if (!searchTerm) return;

    const termExists = devicesList.value.some((device) => device.device_uuid.includes(searchTerm));
    if (termExists) return;

    const searchedDevices = await fetchDeviceList(1, searchTerm);
    const uniqueDevices = searchedDevices.filter(
      (searchedDevice) => !devicesList.value.some((device) => device.device_id === searchedDevice.device_id)
    );

    devicesList.value = [...uniqueDevices, ...devicesList.value];
  };

  onMounted(async () => {
    const initialDevices = await fetchDeviceList();
    devicesList.value = initialDevices;
  });

  return {
    devicesList,
    loadMoreDevices,
    isDevicesLoading,
    currentDevicePage,
    totalDevicePages,
    fetchDeviceList,
    onDeviceSearch,
  };
}
