<template>
  <v-container fluid>
    <div v-if="hasCustomSettings">
      <h5 class="fw-semibold">Configurações customizadas</h5>
      <v-divider class="mb-6"></v-divider>
      <v-form>
        <v-row>
          <v-col cols="12" md="12" class="pt-0">
            <SettingField
              :settings="settings"
              :formFields="getCustomSettings()"
              @update:setting="updateSetting"
              class="mb-6"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-if="hasFieldsDnp">
      <v-form>
        <v-row>
          <v-col v-for="(fields, index) in fieldsDnp" :key="'dnp-' + index" cols="12" md="12">
            <DnpField @update:config="handleConfigUpdate" :fields="fields" :config="getSettingsDnp(fields.variavel)" />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import SettingField from './SettingField.vue';
import DnpField from './DnpField.vue';

export default defineComponent({
  name: 'DeviceConfiguration',
  components: {
    SettingField,
    DnpField,
  },
  props: {
    fields: {
      type: Object as PropType<any>,
      required: true,
    },
    settings: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const fieldsDnp = computed(() => props.fields?.dnp_map ?? []);
    const hasCustomSettings = computed(() => Object.keys(props.fields.custom_settings).length > 0);
    const hasFieldsDnp = computed(() => fieldsDnp?.value?.length > 0);

    const getSettingsDnp = (variavel: string) => {
      return props?.settings?.dnp_data?.find((item: any) => item.variavel === variavel);
    };

    const getCustomSettings = () => {
      return props?.fields?.custom_settings;
    };

    const updateSetting = (setting: any) => {
      if (setting) {
        emit('update:setting', setting);
      }
    };

    const handleConfigUpdate = (update: { variavel: string; type: string; value: any }) => {
      const ifFirstConfig = !props?.settings?.dnp_data?.length;
      const actualDnpMap = props?.settings?.dnp_data || props.fields?.dnp_map;
      const createDnpMap = actualDnpMap.map((item) => {
        const { variavel, valor, 'Tag DNP': tagDNP, 'Endereço DNP': enderecoDNP } = item;

        const newItem = {
          valor: ifFirstConfig ? valor?.default : valor,
          'Tag DNP': ifFirstConfig ? tagDNP?.default : tagDNP,
          variavel,
          'Endereço DNP': ifFirstConfig ? enderecoDNP?.default : enderecoDNP,
        };

        if (variavel === update.variavel) {
          return { ...newItem, [update.type]: update.value };
        }

        return newItem;
      });

      emit('update:deviceConfig', {
        dnp_map: createDnpMap,
      });
    };

    return {
      fieldsDnp,
      getSettingsDnp,
      hasCustomSettings,
      hasFieldsDnp,
      getCustomSettings,
      updateSetting,
      handleConfigUpdate,
    };
  },
});
</script>
