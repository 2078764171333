<template>
  <div class="v-card-header d-flex mb-7 ml-2">
    <h4 class="mb-0">Dashboard Smart Grid Sensor</h4>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Estado do Elo" :iframeSrc="getGrafanaSmartGridPanelUrl(5)"></IframeCard>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Presença de Tensão" :iframeSrc="getGrafanaSmartGridPanelUrl(6)"></IframeCard>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Posição de Chaves" :iframeSrc="getGrafanaSmartGridPanelUrl(1)"></IframeCard>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Nivel de Sinal" :iframeSrc="getGrafanaSmartGridPanelUrl(2)"></IframeCard>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Nivel de Bateria" :iframeSrc="getGrafanaSmartGridPanelUrl(3)"></IframeCard>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <IframeCard cardTitle="Corrente do Elo" :iframeSrc="getGrafanaSmartGridPanelUrl(7)"></IframeCard>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <v-card class="mb-25 audienceOverview-box pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Dispositivos</h5>
        </div>

        <div class="dashboard">
          <iframe :src="getGrafanaSmartGridPanelUrl(4)" frameborder="0" width="100%" height="400px"></iframe>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IframeCard from './component/IframeCard.vue';
import { getGrafanaSmartGridPanelUrl } from '../../utils/grafana';

export default defineComponent({
  name: 'SmartGridSensorView',
  components: { IframeCard },
  data() {
    return {
      getGrafanaSmartGridPanelUrl,
    };
  },
});
</script>
