import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_3 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_4 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_7 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_8 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_11 = { class: "dashboard" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IframeCard = _resolveComponent("IframeCard")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-card-header d-flex mb-7 ml-2" }, [
      _createElementVNode("h4", { class: "mb-0" }, "Dashboard Smart Grid Sensor")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Estado do Elo",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(5)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Presença de Tensão",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(6)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Posição de Chaves",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(1)
        }, null, 8, ["iframeSrc"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Nivel de Sinal",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(2)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Nivel de Bateria",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(3)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Corrente do Elo",
          iframeSrc: _ctx.getGrafanaSmartGridPanelUrl(7)
        }, null, 8, ["iframeSrc"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_v_card, { class: "mb-25 audienceOverview-box pingo-card" }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
              _createElementVNode("h5", { class: "mb-0" }, "Dispositivos")
            ], -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("iframe", {
                src: _ctx.getGrafanaSmartGridPanelUrl(4),
                frameborder: "0",
                width: "100%",
                height: "400px"
              }, null, 8, _hoisted_12)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}