export const formatDate = (dateString: string | undefined, includeTime = true): string => {
  if (!dateString) return '-';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Data inválida';
  }

  const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const formattedDate = dateFormatter.format(date);

  if (includeTime) {
    const formattedTime = timeFormatter.format(date);
    return `${formattedDate} às ${formattedTime}`;
  }

  return formattedDate;
};

export const formatEpochDate = (epoch: number | undefined, includeTime = true): string => {
  if (epoch === undefined) return '-';

  const date = new Date(epoch * 1000);
  if (isNaN(date.getTime())) {
    return 'Data inválida';
  }

  const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const formattedDate = dateFormatter.format(date);

  if (includeTime) {
    const formattedTime = timeFormatter.format(date);
    return `${formattedDate} às ${formattedTime}`;
  }

  return formattedDate;
};

export function formatDateToYMDHM(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatAddress(street, neighborhood, city, zipcode) {
  const addressParts = [street, neighborhood, city, zipcode];

  const nonEmptyParts = addressParts.filter((part) => part);

  if (nonEmptyParts.length === 0) return '-';

  const formattedAddress = nonEmptyParts.join(', ');

  return formattedAddress;
}

export const truncateText = (text: string | null, maxLength: number) => {
  if (text == null) return 'Nenhuma observação informada';
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

export const replaceSpacesWithHyphens = (inputString) => {
  return inputString.replace(/\s+/g, '-').toLowerCase();
};

export function formatDateRange(dates: any) {
  if (!dates || dates.length === 0) {
    return null;
  }

  dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[dates.length - 1]);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
}
export const getTypeInput = (type: string) => {
  if (type === 'inteiro' || type === 'float') return 'number';
  return 'text';
};
