import { ref, onMounted } from 'vue';
import { getDevices } from '@/services/devices';

export function useOperationalNumber() {
  const operationalNumberList = ref<any[]>([]);
  const totalOperationalNumber = ref<number>(0);
  const currentOperationalNumberPage = ref<number>(1);
  const isOperationalNumberLoading = ref<boolean>(false);

  const fetchOperationalNumberList = async (page = 1, searchTerm = '') => {
    isOperationalNumberLoading.value = true;
    try {
      const { totalPages, filteredRecord } = await getDevices({ page, search: searchTerm });
      totalOperationalNumber.value = totalPages;
      currentOperationalNumberPage.value = page;

      return filteredRecord
        .filter((device) => device.operational_number)
        .map((device) => {
          return {
            device_id: device.id,
            operational_number: device.operational_number,
          };
        });
    } catch (error) {
      console.error('Failed to load operational numbers:', error);
      return [];
    } finally {
      isOperationalNumberLoading.value = false;
    }
  };

  const loadMoreOperationalNumber = async ({ done }) => {
    if (currentOperationalNumberPage.value < totalOperationalNumber.value) {
      const newDevices = await fetchOperationalNumberList(currentOperationalNumberPage.value + 1);

      newDevices.forEach((newDevice) => {
        const exists = operationalNumberList.value.some((device) => device.device_id === newDevice.device_id);
        if (!exists) {
          operationalNumberList.value.push(newDevice);
        }
      });
    }
    done();
  };

  const onOperationalNumberSearch = async (searchTerm: string) => {
    if (!searchTerm) return;

    const termExists = operationalNumberList.value.some(
      (device) => device.device_uuid && device.device_uuid.includes(searchTerm)
    );
    if (termExists) return;

    const searchedDevices = await fetchOperationalNumberList(1, searchTerm);
    const uniqueDevices = searchedDevices.filter(
      (searchedDevice) => !operationalNumberList.value.some((device) => device.device_id === searchedDevice.device_id)
    );

    operationalNumberList.value = [...uniqueDevices, ...operationalNumberList.value];
  };

  onMounted(async () => {
    const initialDevices = await fetchOperationalNumberList();
    operationalNumberList.value = initialDevices;
  });

  return {
    operationalNumberList,
    loadMoreOperationalNumber,
    isOperationalNumberLoading,
    currentOperationalNumberPage,
    totalOperationalNumber,
    fetchOperationalNumberList,
    onOperationalNumberSearch,
  };
}
