import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon dnp-content" }
const _hoisted_2 = { class: "w-100 title" }
const _hoisted_3 = { class: "fw-semibold" }
const _hoisted_4 = { class: "map-content" }
const _hoisted_5 = {
  key: 0,
  class: "w-100"
}
const _hoisted_6 = {
  key: 1,
  class: "w-100"
}
const _hoisted_7 = {
  key: 2,
  class: "w-100"
}
const _hoisted_8 = {
  key: 3,
  class: "toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_v_divider)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ((_ctx.valueConfig || _ctx.valueDNP) && !_ctx.isBooleanType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_v_text_field, {
              label: "Valor",
              onInput: _cache[0] || (_cache[0] = (value) => _ctx.handleValueChange(value.target.value, 'valor')),
              type: _ctx.getTypeInput(_ctx.currentValueType),
              modelValue: _ctx.localValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event))
            }, null, 8, ["type", "modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.tagConfig || _ctx.tagDNP)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_v_text_field, {
              label: "Tag DNP",
              onInput: _cache[2] || (_cache[2] = (value) => _ctx.handleValueChange(value.target.value, 'tagDNP')),
              type: _ctx.getTypeInput(_ctx.currentTagType),
              modelValue: _ctx.localTag,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localTag) = $event))
            }, null, 8, ["type", "modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.enderecoConfig || _ctx.enderecoDNP)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_v_text_field, {
              label: "Endereço DNP",
              type: _ctx.getTypeInput(_ctx.currentEnderecoType),
              modelValue: _ctx.localEndereco,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEndereco) = $event)),
              onInput: _cache[5] || (_cache[5] = (value) => _ctx.handleValueChange(value.target.value, 'enderecoDNP'))
            }, null, 8, ["type", "modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isBooleanType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_v_btn_toggle, {
              modelValue: _ctx.localValue,
              "onUpdate:modelValue": [
                _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue) = $event)),
                _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleValueChange($event)))
              ],
              rounded: "0",
              group: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  value: 1,
                  text: "Habilitado"
                }),
                _createVNode(_component_v_btn, {
                  value: 0,
                  text: "Desabilitado"
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}