import { ref, onMounted } from 'vue';
import { getUsers } from '@/services/users';

export function useUsers() {
  const usersList = ref<any[]>([]);
  const totalUserPages = ref<number>(0);
  const currentUserPage = ref<number>(1);
  const isUsersLoading = ref<boolean>(false);

  const fetchUserList = async (page = 1, searchTerm = '') => {
    isUsersLoading.value = true;
    try {
      const { totalPages, filteredRecord } = await getUsers({ page, search: searchTerm });
      totalUserPages.value = totalPages;
      currentUserPage.value = page;

      return filteredRecord.map((user) => {
        return {
          user_id: user.id,
          user_name: user.name,
        };
      });
    } catch (error) {
      console.error('Failed to load users:', error);
      return [];
    } finally {
      isUsersLoading.value = false;
    }
  };

  const loadMoreUsers = async ({ done }) => {
    if (currentUserPage.value < totalUserPages.value) {
      const newUsers = await fetchUserList(currentUserPage.value + 1);

      newUsers.forEach((newUser) => {
        const exists = usersList.value.some((user) => user.user_id === newUser.user_id);
        if (!exists) {
          usersList.value.push(newUser);
        }
      });
    }
    done();
  };

  const onUserSearch = async (searchTerm: string) => {
    if (!searchTerm) return;

    const termExists = usersList.value.some((user) => user.user_name.includes(searchTerm));
    if (termExists) return;

    const searchedUsers = await fetchUserList(1, searchTerm);
    const uniqueUsers = searchedUsers.filter(
      (searchedUser) => !usersList.value.some((user) => user.user_id === searchedUser.user_id)
    );

    usersList.value = [...uniqueUsers, ...usersList.value];
  };

  onMounted(async () => {
    const initialUsers = await fetchUserList();
    usersList.value = initialUsers;
  });

  return {
    usersList,
    loadMoreUsers,
    isUsersLoading,
    currentUserPage,
    totalUserPages,
    fetchUserList,
    onUserSearch,
  };
}
