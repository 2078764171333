import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-center align-items-center min-vh-full"
}
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "pingo-form-group without-icon" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "col-lg-4 col-md-4 col-sm-4 mt-12 location-input" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { class: "pingo-form-group without-icon" }
const _hoisted_9 = { class: "pingo-form-group without-icon" }
const _hoisted_10 = { class: "pingo-form-group without-icon" }
const _hoisted_11 = { class: "multi-select-input" }
const _hoisted_12 = { class: "pingo-form-group without-icon" }
const _hoisted_13 = { class: "form-control" }
const _hoisted_14 = { class: "text-end mt-30" }
const _hoisted_15 = ["loading"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "text-end mt-30" }
const _hoisted_18 = ["loading"]
const _hoisted_19 = {
  key: 1,
  class: "d-flex justify-center align-items-center min-vh-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_GeoDialog = _resolveComponent("GeoDialog")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_DeviceConfiguration = _resolveComponent("DeviceConfiguration")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar Dispositivo",
      mainTitle: "Editar Dispositivo"
    }),
    _createVNode(_component_v_card, { class: "tabs-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          "align-tabs": "start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, { value: _ctx.TAB_INFO }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("Informações")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.DEVICE_CONFIGURATION_TAB }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Configurações")
              ])),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "mb-30 pingo-card" }, {
              default: _withCtx(() => [
                (_ctx.loadingData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_progress_linear, {
                        color: "grey",
                        indeterminate: ""
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_v_tabs_window, {
                      key: 1,
                      modelValue: _ctx.tab,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.tab) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_INFO }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, { fluid: "" }, {
                              default: _withCtx(() => [
                                _createElementVNode("form", {
                                  onSubmit: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                                  class: "form"
                                }, [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_2, [
                                            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Dispositivo *", -1)),
                                            _createVNode(_component_v_text_field, {
                                              type: "text",
                                              placeholder: "Número do dispositivo",
                                              modelValue: _ctx.device.device_uuid,
                                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.device.device_uuid) = $event)),
                                              autocomplete: "off",
                                              required: ""
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_3, [
                                            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tecnologia *", -1)),
                                            _createVNode(_component_v_select, {
                                              label: "Selecione a tecnologia",
                                              items: _ctx.EQUIPMENT_TECNOLOGIES,
                                              modelValue: _ctx.device.technology,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.device.technology) = $event)),
                                              required: ""
                                            }, null, 8, ["items", "modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_4, [
                                            _cache[23] || (_cache[23] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Status *", -1)),
                                            _createVNode(_component_v_select, {
                                              label: "Selecione o status",
                                              items: ['Ativo', 'Inativo'],
                                              modelValue: _ctx.device.status,
                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.device.status) = $event)),
                                              required: ""
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_5, [
                                            _cache[24] || (_cache[24] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tipo de Dispositivo *", -1)),
                                            _createVNode(_component_v_select, {
                                              label: "Selecione o tipo de dispositivo",
                                              items: _ctx.devicesTypes,
                                              modelValue: _ctx.device.type_id,
                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.device.type_id) = $event)),
                                              required: ""
                                            }, null, 8, ["items", "modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_GeoDialog, {
                                          modelValue: _ctx.device.location,
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.device.location) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_7, [
                                            _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número da Linha", -1)),
                                            _createVNode(_component_v_text_field, {
                                              type: "text",
                                              class: "input-control",
                                              placeholder: "Número da Linha",
                                              modelValue: _ctx.device.line_number,
                                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.device.line_number) = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_8, [
                                            _cache[26] || (_cache[26] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número Operacional", -1)),
                                            _createVNode(_component_v_text_field, {
                                              type: "text",
                                              class: "input-control",
                                              placeholder: "Número Operacional",
                                              modelValue: _ctx.device.operational_number,
                                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.device.operational_number) = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_9, [
                                            _cache[27] || (_cache[27] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nivel de Bateria", -1)),
                                            _createVNode(_component_v_text_field, {
                                              type: "text",
                                              class: "input-control",
                                              placeholder: "Nivel de Bateria",
                                              modelValue: _ctx.device.power,
                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.device.power) = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_10, [
                                            _cache[28] || (_cache[28] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Temperatura", -1)),
                                            _createVNode(_component_v_text_field, {
                                              type: "text",
                                              class: "input-control",
                                              placeholder: "Temperatura",
                                              modelValue: _ctx.device.temperature,
                                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.device.temperature) = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-6 col-md-6 col-sm-6" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_11, [
                                            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Cliente", -1)),
                                            _createVNode(_component_MultiSelect, {
                                              modelValue: _ctx.device.client_id,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.device.client_id) = $event)),
                                              itemsList: _ctx.clients,
                                              itemValue: 'client_id',
                                              itemTitle: 'client_name',
                                              id: 'client',
                                              label: 'Cliente',
                                              placeholder: 'Selecione um cliente',
                                              noDataText: 'Nenhum cliente encontrado',
                                              multiple: false,
                                              currentPage: _ctx.currentClientPage,
                                              totalPages: _ctx.totalClientPages,
                                              "onUpdate:search": _ctx.onSearch,
                                              onLoadMoreItems: _ctx.loadMoreClients
                                            }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, { class: "col-lg-12 col-md-12 col-sm-12" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_12, [
                                            _cache[30] || (_cache[30] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Observações ", -1)),
                                            _createElementVNode("div", _hoisted_13, [
                                              _withDirectives(_createElementVNode("textarea", {
                                                class: "input-control text-area text-area",
                                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.device.observations) = $event))
                                              }, null, 512), [
                                                [_vModelText, _ctx.device.observations]
                                              ])
                                            ])
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_snackbar, {
                                    modelValue: _ctx.isSnackbarVisible,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isSnackbarVisible) = $event)),
                                    timeout: 3000,
                                    top: "",
                                    right: ""
                                  }, {
                                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                                      _createTextVNode(" Configurações salvas com sucesso! ")
                                    ])),
                                    _: 1
                                  }, 8, ["modelValue"]),
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("button", {
                                      type: "button",
                                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                                      class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                                    }, " Voltar "),
                                    _createElementVNode("button", {
                                      type: "submit",
                                      loading: _ctx.loading,
                                      class: _normalizeClass([{ 'disabled-btn': !_ctx.isModified }, "default-btn ps-30 pe-30"])
                                    }, " Salvar alterações ", 10, _hoisted_15)
                                  ])
                                ], 32)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]),
                        _createVNode(_component_v_tabs_window_item, { value: _ctx.DEVICE_CONFIGURATION_TAB }, {
                          default: _withCtx(() => [
                            (_ctx.deviceConfigurationFields || _ctx.deviceConfiguration)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createVNode(_component_DeviceConfiguration, {
                                    "onUpdate:setting": _ctx.handleDeviceCustomConfigUpdate,
                                    "onUpdate:deviceConfiguration": _ctx.handleDeviceDnpConfigUpdate,
                                    fields: _ctx.deviceConfigurationFields,
                                    settings: _ctx.deviceConfiguration
                                  }, null, 8, ["onUpdate:setting", "onUpdate:deviceConfiguration", "fields", "settings"]),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createElementVNode("button", {
                                      type: "button",
                                      onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                                      class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                                    }, " Voltar "),
                                    _createElementVNode("button", {
                                      type: "submit",
                                      loading: _ctx.loadingSettings,
                                      class: "default-btn ps-30 pe-30",
                                      onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.saveDeviceConfig && _ctx.saveDeviceConfig(...args)))
                                    }, [
                                      (_ctx.loadingSettings)
                                        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                                            key: 0,
                                            indeterminate: "",
                                            color: "white",
                                            size: "20"
                                          }))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createTextVNode(" Salvar alterações ")
                                          ], 64))
                                    ], 8, _hoisted_18)
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                  _createVNode(_component_v_progress_linear, {
                                    color: "grey",
                                    indeterminate: ""
                                  })
                                ]))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmationDialog, {
      visible: _ctx.confirmDialog,
      "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.confirmDialog) = $event)),
      title: "Confirmação",
      message: "Existem alterações não salvas. Tem certeza de que deseja deseja voltar?",
      onConfirm: _ctx.confirmBack
    }, null, 8, ["visible", "onConfirm"])
  ], 64))
}