import axios from 'axios';
import store from '@/store';
import { UsersResponse, User, FilterParams, UserResponse } from '../utils/types';

export async function getUsers(params: FilterParams = {}): Promise<UserResponse> {
  const data = {
    search: params.search || '',
    orderBy: params.orderBy || '',
    dir: params.dir || 'asc',
    itemsPerPage: params.itemsPerPage || 10,
    page: params.page || 1,
  };

  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/users`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
      params: data,
    });

    return response.data as UserResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getUser(id: number): Promise<User> {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/users/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data.user;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
export async function updateUser(id: number, data: any): Promise<UsersResponse> {
  try {
    const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/users/${id}`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as UsersResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function deleteUser(id: number): Promise<UsersResponse> {
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/users/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as UsersResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function createUser(data: any): Promise<UsersResponse> {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/users`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as UsersResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function updatePassword(data: any) {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/auth/changePassword`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
