import { ref, onMounted } from 'vue';
import { getClients } from '@/services/clients';

export function useClients() {
  const clients = ref<any[]>([]);
  const totalClientPages = ref<number>(0);
  const currentClientPage = ref<number>(1);
  const isClientsLoading = ref<boolean>(false);

  const fetchClients = async (page = 1, searchTerm = '') => {
    isClientsLoading.value = true;
    try {
      const { totalPages, filteredRecord } = await getClients({ page, search: searchTerm });
      totalClientPages.value = totalPages;
      currentClientPage.value = page;

      return filteredRecord.map((client) => {
        return {
          client_name: client.name,
          client_id: client.id,
        };
      });
    } catch (error) {
      console.error('Failed to load clients:', error);
      return [];
    } finally {
      isClientsLoading.value = false;
    }
  };

  const loadMoreClients = async ({ done }) => {
    if (currentClientPage.value <= totalClientPages.value) {
      const newClients = await fetchClients(currentClientPage.value + 1);

      newClients.forEach((newClient) => {
        const exists = clients.value.some((client) => client.client_id === newClient.client_id);
        if (!exists) {
          clients.value.push(newClient);
        }
      });
    }
    done();
  };

  const onSearch = async (searchTerm: string) => {
    if (!searchTerm) return;

    const termExists = clients.value.some((client) => client.client_name.includes(searchTerm));
    if (termExists) return;

    const searchedClients = await fetchClients(1, searchTerm);
    const uniqueClients = searchedClients.filter(
      (searchedClient) => !clients.value.some((client) => client.client_id === searchedClient.client_id)
    );

    clients.value = [...uniqueClients, ...clients.value];
  };

  onMounted(async () => {
    const initialClients = await fetchClients();
    clients.value = initialClients;
  });

  return {
    clients,
    loadMoreClients,
    isClientsLoading,
    totalClientPages,
    currentClientPage,
    fetchClients,
    onSearch,
  };
}
