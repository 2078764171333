<template>
  <div class="v-card-header d-flex mb-12 ml-2">
    <h4 class="mb-0">Dispositivos</h4>
  </div>
  <v-card class="mb-25 pingo-card device-card">
    <div class="pingo-table-content">
      <div class="pingo-table v-elevation-z8">
        <div class="pingo-table-content">
          <DataTable
            :headers="headers"
            :items="devices"
            :loading="isLoading"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page="currentPage"
            @update:options="handleOptionsChange"
          >
            <template v-slot:top>
              <div class="d-flex align-items-center justify-content-space-between mt-10 mb-4">
                <div class="d-flex align-items-center form-search-table">
                  <form class="pingo-search-box" @submit.prevent>
                    <input
                      type="text"
                      class="input-search border-radius"
                      placeholder="Pesquise aqui..."
                      v-model="searchQuery"
                      @input="onSearchInput"
                    />
                    <button type="submit"><i class="ri-search-line"></i></button>
                  </form>
                </div>
                <div>
                  <v-btn
                    class="default-btn shadow-none elevation-0 button-create"
                    v-if="canAccess(userRole, 'create-devices')"
                  >
                    <i class="ri-add-fill"></i>
                    Criar novo dispositivo

                    <v-dialog v-model="dialog" activator="parent" width="auto">
                      <div class="create-dialog-box">
                        <div class="title d-flex align-items-center justify-space-between">
                          <h4 class="mb-0">Criar Novo Dispositivo</h4>
                          <button class="close-btn bg-transparent p-0 border-none elevation-0" @click="dialog = false">
                            <i class="flaticon-close"></i>
                          </button>
                        </div>
                        <form @submit.prevent="onCreateDevice" class="form">
                          <v-row>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Dispositivo *</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Número do dispositivo"
                                  v-model="form.device_uuid"
                                  autocomplete="off"
                                  required
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="pingo-form-group without-icon">
                                <label class="d-block mb-10 fw-semibold gray-color">Tecnologia *</label>
                                <v-select
                                  label="Selecione a tecnologia"
                                  :items="EQUIPMENT_TECNOLOGIES"
                                  v-model="form.technology"
                                  required
                                ></v-select>
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="pingo-form-group without-icon">
                                <label class="d-block mb-10 fw-semibold gray-color">Status *</label>
                                <v-select
                                  label="Selecione o status"
                                  :items="['Ativo', 'Inativo']"
                                  v-model="form.status"
                                  required
                                ></v-select>
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="pingo-form-group without-icon">
                                <label class="d-block mb-10 fw-semibold gray-color">Tipo de Dispositivo *</label>
                                <v-select
                                  label="Selecione o tipo de dispositivo"
                                  :items="devicesTypes"
                                  v-model="form.type_id"
                                  required
                                ></v-select>
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <GeoDialog v-model="form.location" />
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Nivel de Bateria</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Nivel de Bateria"
                                  v-model="form.power"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Temperatura</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Temperatura"
                                  v-model="form.temperature"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Número da Linha</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Número da Linha"
                                  v-model="form.line_number"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Número Operacional</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Número Operacional"
                                  v-model="form.operational_number"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6">
                              <div class="multi-select-input users">
                                <label class="d-block mb-10 fw-semibold gray-color">Cliente</label>
                                <MultiSelect
                                  v-model="form.client_id"
                                  :itemsList="clients"
                                  :itemValue="'client_id'"
                                  :itemTitle="'client_name'"
                                  :id="'client'"
                                  :label="'Cliente'"
                                  :placeholder="'Selecione um cliente'"
                                  :multiple="false"
                                  :noDataText="'Nenhum cliente encontrado'"
                                  :currentPage="currentClientPage"
                                  :totalPages="totalClientPages"
                                  @update:search="onSearch"
                                  @load-more-items="loadMoreClients"
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" lg="12" md="12">
                              <div class="pingo-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color"> Observações </label>
                                <div class="form-control">
                                  <textarea class="input-control text-area" v-model="form.observations" />
                                </div>
                              </div>
                            </v-col>
                          </v-row>

                          <div class="text-end mt-15">
                            <v-tooltip v-if="!isFormValid" location="top">
                              <template v-slot:activator="{ props }">
                                <button type="submit" class="button-desactive" disabled="true" v-bind="props">
                                  <i class="ri-add-fill"></i> Criar Novo Dispositivo
                                </button>
                              </template>
                              <span>Por favor, preencha todos os campos corretamente antes da criação.</span>
                            </v-tooltip>

                            <button v-else type="submit"><i class="ri-add-fill"></i> Criar Novo Dispositivo</button>
                          </div>
                        </form>
                      </div>
                    </v-dialog>
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <tr>
                <td class="gray-color">{{ item.client_name ?? '-' }}</td>
                <td class="gray-color">
                  {{ item.operational_number ?? '-' }}
                </td>
                <td class="gray-color">{{ item.line_number ?? '-' }}</td>
                <td class="gray-color">{{ item.type_name }}</td>
                <td>
                  <div class="gray-color">
                    {{ item.device_uuid }}
                  </div>
                </td>
                <td class="gray-color">
                  <span class="badge fw-medium" :class="computeStatusClass(item.status)">
                    {{ item.status ? 'Ativo' : 'Inativo' }}
                  </span>
                </td>
                <td class="gray-color">{{ item.updated_at }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <button
                        type="button"
                        v-bind="props"
                        class="position-relative dot-btn p-0 bg-transparent border-none"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                    </template>
                    <v-list class="dotMenu two monthMenu">
                      <button
                        @click="handleEditDevice(item.id)"
                        type="button"
                        class="bg-transparent border-none position-relative"
                        v-if="canAccess(userRole, 'edit-devices')"
                      >
                        <i class="ri-edit-line"></i> Editar
                      </button>
                      <button
                        type="button"
                        class="bg-transparent border-none position-relative"
                        @click="openDeleteModal(item.id)"
                        v-if="canAccess(userRole, 'remove-devices')"
                      >
                        <i class="ri-delete-bin-line"></i> Remover
                      </button>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template v-slot:bottom>
              <div class="footer-table">
                <div class="text-gray-color">
                  <span>
                    Total de <strong>{{ isFiltered ? filteredItemsCount : totalItems }}</strong> resultados
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-end pt-2">
                    <v-pagination
                      v-model="currentPage"
                      :length="totalPages"
                      next-icon="mdi-chevron-right"
                      prev-icon="mdi-chevron-left"
                      :total-visible="TABLE_PAGINATION_ITEMS"
                      @update:model-value="onPageChange"
                      color="#7a7e87"
                    ></v-pagination>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <span class="text-gray-color">Mostrar</span>
                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 20, 50]"
                    label="Linhas por página"
                    class="items-per-page"
                    density="compact"
                    variant="underlined"
                    hide-details
                    single-line
                    @update:model-value="onItemsPerPageChange"
                  ></v-select>
                  <span class="text-gray-color">por página</span>
                </div>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
    <v-dialog v-model="confirmDeleteDialog" max-width="570px" class="py-20">
      <v-card class="pingo-card py-6 px-4">
        <v-card-title class="headline m-5">Você realmente deseja remover este dispositivo?</v-card-title>
        <v-card-text
          >Tem certeza de que deseja excluir este dispositivo? Esta ação é irreversível e não pode ser
          desfeita!</v-card-text
        >
        <v-card-actions>
          <v-btn color="primary" class="cancel-btn" @click="confirmDeleteDialog = false">Cancelar</v-btn>
          <v-btn color="red" class="remove-btn elevation-0" @click="confirmDelete">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Device, DeviceType, Form } from '@/utils/types';
import { formatDate, truncateText } from '@/utils/format';
import { createDevice, deleteDevice, getDevices, getDevicesType } from '@/services/devices';
import { EQUIPMENT_TECNOLOGIES, EQUIPMENT_TYPES } from '@/utils/constants';
import DataTable from '@/components/common/DataTable.vue';
import { useRouter } from 'vue-router';
import { TABLE_PAGINATION_ITEMS } from '@/utils/constants';
import MultiSelect from '@/components/common/MultiSelect.vue';
import { useClients } from '@/utils/composable/useClients';
import { getUserRole } from '../../utils/store';
import { canAccess } from '../../utils/methods';
import GeoDialog from '@/views/devices/component/GeoDialog.vue';

const ALIGN_CENTER = 'center' as const;

export default defineComponent({
  name: 'DevicesView',
  components: {
    DataTable,
    MultiSelect,
    GeoDialog,
  },
  mounted() {
    document.title = 'Dispositivos';
  },
  data() {
    const userRole = getUserRole();
    const showActions = canAccess(userRole, 'edit-devices') || canAccess(userRole, 'remove-devices');

    return {
      canAccess,
      userRole,
      headers: [
        {
          align: ALIGN_CENTER,
          title: 'Cliente',
          key: 'client_name',
          sortable: true,
          class: 'fw-semibold gray-color text-start',
        },
        {
          align: ALIGN_CENTER,
          title: 'Nº Operacional',
          key: 'operational_number',
          sortable: true,
          class: 'fw-semibold gray-color text-start',
        },
        {
          align: ALIGN_CENTER,
          title: 'Nº Linha',
          key: 'line_number',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Tipo',
          key: 'type_name',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Dispositivo',
          key: 'device_uuid',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Status',
          key: 'status',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Data de Atualização',
          key: 'updated_at',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        ...(showActions
          ? [
              {
                align: ALIGN_CENTER,
                title: 'Ações',
                key: 'actions',
                sortable: false,
                class: 'fw-semibold gray-color',
              },
            ]
          : []),
      ],
      TABLE_PAGINATION_ITEMS,
    };
  },
  setup() {
    const devices = ref<Device[]>([]);
    const devicesTypes = ref<string[]>([]);
    const dialog = ref(false);
    const router = useRouter();
    const form = ref<Form>({
      device_uuid: '',
      technology: null,
      status: null,
      power: null,
      temperature: null,
      observations: '',
      line_number: '',
      operational_number: '',
      type_id: null,
      location: null,
      client_id: null,
    });
    const defaultForm = () => ({
      device_uuid: '',
      technology: null,
      status: null,
      power: null,
      temperature: null,
      observations: '',
      line_number: '',
      operational_number: '',
      type_id: null,
      location: null,
      client_id: null,
    });

    const confirmDeleteDialog = ref(false);
    const deviceToDeleteId = ref<number | null>(null);

    const itemsPerPage = ref(10);
    const totalItems = ref(0);
    const filteredItemsCount = ref(0);
    const totalPages = ref(0);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const searchQuery = ref('');
    const orderBy = ref('');
    const dir = ref('');

    const { clients, loadMoreClients, isClientsLoading, totalClientPages, currentClientPage, fetchClients, onSearch } =
      useClients();

    const onCreateDevice = async () => {
      if (!isFormValid.value) return;

      const data = {
        ...form.value,
        status: form.value.status === 'Ativo' ? 1 : 0,
        type_id: form.value.type_id !== null ? EQUIPMENT_TYPES[form.value.type_id] : 0,
        latitude: form.value.location && form.value.location.latitude,
        longitude: form.value.location && form.value.location.longitude,
      };

      console.log('data: ', data);

      try {
        await createDevice(data);
        dialog.value = false;
        form.value = defaultForm();
        await loadDevices();
      } catch (error) {
        console.error('Erro ao criar dispositivo:', error);
      }
    };

    const loadDevices = async (search = '', page = 1, itemsPerPage = 10) => {
      isLoading.value = true;
      try {
        const response = await getDevices({
          search: search,
          orderBy: orderBy.value,
          dir: dir.value,
          itemsPerPage: itemsPerPage,
          page: page,
        });
        devices.value = response.filteredRecord.map((device) => ({
          ...device,
          updated_at: formatDate(device.updated_at, true),
          device_uuid: truncateText(device.device_uuid, 25),
        }));
        totalItems.value = response.allRecordsCount;
        filteredItemsCount.value = response.allRecordsCountFiltered;
        totalPages.value = response.totalPages;
      } catch (error) {
        console.error('Erro ao carregar dispositivos:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadDeviceTypes = async () => {
      try {
        const { types } = await getDevicesType();
        devicesTypes.value = types.map((type: DeviceType) => type.name);
      } catch (error) {
        console.error('Erro ao carregar tipos de dispositivos:', error);
      }
    };

    const computeClass = (value: number, thresholds: Array<{ limit: number; className: string }>) => {
      const threshold = thresholds.find((t) => value >= t.limit);
      return threshold ? threshold.className : '';
    };

    const computeStatusClass = (status: number) =>
      computeClass(status, [
        { limit: 1, className: 'status-active' },
        { limit: 0, className: 'status-inactive' },
      ]);

    onMounted(async () => {
      await loadDevices();
      await loadDeviceTypes();
    });

    const isFormValid = computed(() => {
      const { device_uuid, technology, status, type_id } = form.value;
      return [device_uuid, technology, status, type_id].every(Boolean);
    });

    const handleEditDevice = (deviceId: number) => {
      router.push({ name: 'DeviceEdit', params: { id: deviceId } });
    };

    const handleDeleteDevice = async (deviceId: number) => {
      try {
        await deleteDevice(deviceId).then(() => {
          loadDevices();
          confirmDeleteDialog.value = false;
        });
      } catch (error) {
        console.error('Erro ao remover dispositivo:', error);
      }
    };

    const openDeleteModal = (deviceId: number) => {
      deviceToDeleteId.value = deviceId;
      confirmDeleteDialog.value = true;
    };

    const confirmDelete = async () => {
      if (deviceToDeleteId.value !== null) {
        await handleDeleteDevice(deviceToDeleteId.value);
      }
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      loadDevices(searchQuery.value, currentPage.value, itemsPerPage.value);
    };

    const onItemsPerPageChange = (items: number) => {
      itemsPerPage.value = items;
      currentPage.value = 1;
      loadDevices(searchQuery.value, currentPage.value, itemsPerPage.value);
    };

    const onSearchInput = () => {
      loadDevices(searchQuery.value);
    };

    const isFiltered = computed(() => {
      return searchQuery.value.trim() !== '' || filteredItemsCount.value !== totalItems.value;
    });

    const handleOptionsChange = (options) => {
      const hasSortByValue = options.sortBy.length > 0;

      if (!options) return;

      const orderByValue = options.sortBy[0];

      orderBy.value = hasSortByValue ? orderByValue.key : '';
      dir.value = hasSortByValue ? orderByValue.order : '';
      loadDevices(searchQuery.value, currentPage.value, itemsPerPage.value);
    };

    return {
      devices,
      computeStatusClass,
      formatDate,
      truncateText,
      isFormValid,
      form,
      dialog,
      onCreateDevice,
      EQUIPMENT_TECNOLOGIES,
      devicesTypes,
      handleEditDevice,
      handleDeleteDevice,
      confirmDeleteDialog,
      openDeleteModal,
      confirmDelete,
      isLoading,
      itemsPerPage,
      totalItems,
      totalPages,
      loadDevices,
      loadDeviceTypes,
      deviceToDeleteId,
      defaultForm,
      onPageChange,
      onItemsPerPageChange,
      currentPage,
      searchQuery,
      onSearchInput,
      isFiltered,
      filteredItemsCount,
      clients,
      handleOptionsChange,
      loadMoreClients,
      isClientsLoading,
      totalClientPages,
      currentClientPage,
      fetchClients,
      onSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-col-md-6,
.v-col-lg-6,
.v-col-12 {
  padding: 0 12px;
}

.text-area {
  padding: 10px;
  color: #43444b !important;
  resize: none;

  &:focus,
  &:focus-visible {
    border-color: var(--heraBlueColor) !important;
    outline: none;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

.create-dialog-box {
  width: 850px;

  .form {
    overflow-y: auto;
    height: 700px;
    padding-top: 34px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #999;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track:active {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #999;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: #ccc;
    }
  }
}

.button-desactive {
  cursor: not-allowed;
  background-color: #adadad;

  &:hover {
    background-color: #adadad;
  }
}

.custom-badge {
  width: 92px;
}

.status-active {
  color: #1e9b52 !important;
  background-color: #017b3529 !important;
  min-width: 70px;
}

.status-inactive {
  color: #ff3444 !important;
  background-color: #fce6e8 !important;
}

.table .ri-check-line {
  color: #1e9b52;
}

.table .ri-close-line {
  color: #ee368c;
}

.table .ri-thermometer-line {
  color: #f44336;
}

.table .ri-thermometer-low {
  color: #ff9800;
}

.table .ri-thermometer-minus-line {
  color: #4caf50;
}

.remove-btn {
  color: #fff !important;
  background-color: #d94e4e !important;
  font-size: 14px !important;
  padding: 10px 20px;
  height: 40px !important;
  text-transform: capitalize !important;
}

.cancel-btn {
  color: #858585 !important;
  font-size: 14px !important;
  padding: 10px 20px;
  height: 40px !important;
  text-transform: capitalize !important;
}

.form-search-table {
  width: 40% !important;
  margin: 17px 22px 17px 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;

  form {
    width: 100%;
    border-radius: 6px;
  }
}

.button-create {
  height: 49px !important;
  width: 256px !important;

  .ri-add-fill {
    font-size: 18px !important;
  }

  .ri-add-fill:before {
    position: relative !important;
  }
}

.footer-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  .items-per-page {
    border: 1px solid #ededee;
    border-radius: 10px;
    padding: 6px 4px;
    width: 76px;
    margin: 10px;
  }
}

.pingo-search-box .input-search {
  border: 1px solid #ededee !important;
  background-color: #f9f9f9;
}

.pingo-search-box button {
  color: #d1d1d1 !important;
}

::v-deep {
  .v-field__input {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .v-select__selection-text {
    padding-left: 10px !important;
  }

  .v-select__selection-text {
    padding-left: 2px !important;
  }

  .input-control {
    height: 62px !important;
  }

  .v-field__append-inner {
    padding-top: 5px !important;
  }

  .mdi-menu-right::before {
    color: #777777;
  }

  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .v-btn {
    border-radius: 6px !important;
  }

  .v-btn__content {
    text-transform: none !important;
  }

  .v-field__outline__notch {
    &::before {
      display: inline-block;
    }

    &::after {
      display: inline-block;
    }
  }

  .v-autocomplete .v-field .v-text-field__prefix,
  .v-autocomplete .v-field .v-text-field__suffix,
  .v-autocomplete .v-field .v-field__input,
  .v-autocomplete .v-field.v-field {
    padding: 5px 15px !important;
  }

  .multi-select-input.users {
    .v-autocomplete .v-field .v-text-field__prefix,
    .v-autocomplete .v-field .v-text-field__suffix,
    .v-autocomplete .v-field .v-field__input,
    .v-autocomplete .v-field.v-field {
      padding: 7px 8px !important;
    }
  }
}

.text-gray-color {
  color: #777777;
}

.device-card {
  padding-top: 5px !important;
  padding-bottom: 30px;
}

.title-text {
  margin-right: 25px;
  color: var(--blackColor);
  font-size: 16px;
  font-family: var(--headingFontFamily);

  span {
    color: #c3c3c3;
  }
}

::v-deep .v-chip__close {
  background-color: transparent;
  padding: 1px 0px 4px 19px;
  color: var(--whiteColor);
  position: relative;
  right: 25px;
  top: 0px;
}
</style>
