<template>
  <div></div>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    document.title = 'Home';
  },
};
</script>
