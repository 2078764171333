<template>
  <v-autocomplete
    v-model="selectedItems"
    :items="itemsList"
    :item-value="itemValue"
    :item-title="itemTitle"
    :id="id"
    :label="label"
    :density="density"
    :placeholder="placeholder"
    :no-data-text="noDataText"
    multiple
    clearable
    chips
    closable-chips
    variant="outlined"
    bg-color="white"
    color="#35ac67"
    rounded="lg"
    theme="primary"
    class="multi-select"
    @update:search="onSearch"
  >
    <template v-slot:append-item v-if="itemsList.length > 0 && currentPage < totalPages">
      <v-infinite-scroll :height="80" @load="loadMoreItems" color="#35ac67" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    value: Array,
    itemsList: Array,
    itemValue: {
      type: String,
      default: 'value',
    },
    itemTitle: {
      type: String,
      default: 'title',
    },
    id: {
      type: String,
      default: 'multiselect',
    },
    density: {
      type: String,
      default: 'comfortable',
    },
    placeholder: {
      type: String,
      default: 'Select items',
    },
    noDataText: {
      type: String,
      default: 'No data available',
    },
    currentPage: Number,
    totalPages: Number,
  },
  data() {
    return {
      selectedItems: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selectedItems = newValue;
    },
    selectedItems(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    onSearch(searchTerm) {
      this.$emit('update:search', searchTerm);
    },
    loadMoreItems({ done }) {
      this.$emit('load-more-items', { done });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-autocomplete .v-field .v-text-field__prefix,
  .v-autocomplete .v-field .v-text-field__suffix,
  .v-autocomplete .v-field .v-field__input,
  .v-autocomplete .v-field.v-field {
    padding: 10px 15px !important;
  }

  .multi-select {
    width: 100% !important;
  }

  .v-chip__close {
    background-color: transparent;
    color: white;
    font-size: 15px !important;
  }

  .v-chip__close:hover {
    background-color: transparent;
  }

  .v-field .v-chip {
    color: #fff;
    background-color: #5f5f5f !important;
    font-size: 13px !important;
    font-weight: 400;
  }

  .v-field-label {
    background-color: #fff;
    margin-left: 15px;
    padding: 0px 5px;
  }

  .v-field__outline__notch {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}
</style>
