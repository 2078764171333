import axios, { ResponseType } from 'axios';
import store from '@/store';
import { FilterParamsRecord, ReportResponse } from '@/utils/types';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

function buildParams(params: FilterParamsRecord = {}): any {
  return {
    startDate: params.startDate || '',
    endDate: params.endDate || '',
    deviceType: params.deviceType || '',
    devicesId: params.devicesId || [],
    clients: params.clients || [],
    operationalNumber: params.operationalNumber || [],
    lineNumber: params.lineNumber || '',
    status: params.status || [],
    technology: params.technology || [],
    search: params.search || '',
    orderBy: params.orderBy || '',
    direction: params.direction || '',
    itemsPerPage: params.itemsPerPage || '10',
    page: params.page || '1',
  };
}

async function fetchData(url: string, params: any, responseType: ResponseType = 'json'): Promise<any> {
  try {
    const response = await axios.get(url, {
      params: params,
      withCredentials: true,
      headers: {
        Accept:
          responseType === 'blob'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
      responseType: responseType,
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getReports(params: FilterParamsRecord = {}): Promise<ReportResponse> {
  const data = buildParams(params);
  return fetchData(`${baseUrl}/api/reports/device-events`, data);
}

export async function exportDeviceEventReport(params: FilterParamsRecord = {}): Promise<any> {
  const data = buildParams(params);
  return fetchData(`${baseUrl}/api/reports/device-events/export`, data, 'blob');
}
