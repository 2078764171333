import axios from 'axios';
import store from '@/store';
import { ClientResponse, Clients, ClientsResponse, DeviceResponse, FilterParams, UsersResponse } from '@/utils/types';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export async function getClients(params: FilterParams = {}): Promise<ClientResponse> {
  const data = {
    search: params.search || '',
    orderBy: params.orderBy || '',
    dir: params.dir || 'asc',
    itemsPerPage: params.itemsPerPage || 10,
    page: params.page || 1,
  };

  try {
    const response = await axios.get(`${baseUrl}/api/clients`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
      params: data,
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getClient(id: number): Promise<Clients> {
  try {
    const response = await axios.get(`${baseUrl}/api/clients/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function createClient(data: Clients): Promise<ClientsResponse> {
  try {
    const response = await axios.post(`${baseUrl}/api/clients`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as ClientsResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function fetchAddressByPostalCode(postalCode: string): Promise<any | null> {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${postalCode}/json`);

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();

    if (data.erro) {
      throw new Error(`CEP não encontrado: ${postalCode}`);
    }

    return data;
  } catch (error: any) {
    console.error('Erro ao buscar endereço:', error.message);
    return null;
  }
}

export async function updateClient(id: number, data: Clients): Promise<ClientsResponse> {
  try {
    const response = await axios.put(`${baseUrl}/api/clients/${id}`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as ClientsResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function deleteClient(id: number): Promise<ClientsResponse> {
  try {
    const response = await axios.delete(`${baseUrl}/api/clients/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as ClientsResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getUsersByClientId(clientId: number, search?: string, page?: number): Promise<UsersResponse> {
  try {
    const response = await axios.get(`${baseUrl}/api/users/clients/${clientId}`, {
      params: {
        ...(search && { search }),
        page: page || 1,
      },
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    const userList = response.data;

    return userList;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getDevicesByClientId(
  clientId: string | string[],
  search?: string,
  page?: number
): Promise<DeviceResponse> {
  try {
    const response = await axios.get(`${baseUrl}/api/devices/clients/${clientId}`, {
      params: {
        ...(search && { search }),
        page: page || 1,
      },
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    const responseList = response.data;

    return responseList;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function updateDevicesByClientId(clientId: number, devices: any): Promise<any> {
  const data = {
    client_id: clientId,
    devices: devices,
  };

  try {
    const response = await axios.post(`${baseUrl}/api/devices/owner`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as ClientsResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function updateUsersByClientId(clientId: number, users: any): Promise<any> {
  const data = {
    client_id: clientId,
    users: users,
  };

  try {
    const response = await axios.post(`${baseUrl}/api/users/client`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });
    return response.data as ClientsResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
