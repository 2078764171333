<template>
  <div class="v-card-header d-flex mb-12 ml-2">
    <h4 class="mb-0">Usuários</h4>
  </div>
  <v-card class="mb-25 pingo-card pt-5">
    <div class="pingo-table-content">
      <div class="pingo-table v-elevation-z8">
        <div class="pingo-table-content">
          <DataTable
            :headers="headers"
            :items="users"
            :loading="isLoading"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page="currentPage"
            @update:options="handleOptionsChange"
          >
            <template v-slot:top>
              <div class="d-flex align-items-center justify-content-space-between mt-10 mb-4">
                <div class="d-flex align-items-center form-search-table">
                  <form class="pingo-search-box" @submit.prevent>
                    <input
                      type="text"
                      class="input-search border-radius"
                      placeholder="Pesquise aqui..."
                      v-model="searchQuery"
                      @input="onSearchInput"
                    />
                    <button type="submit"><i class="ri-search-line"></i></button>
                  </form>
                </div>
                <div v-if="canAccess(userRole, 'create-users')">
                  <v-btn class="default-btn shadow-none elevation-0 button-create">
                    <i class="ri-add-fill"></i>
                    Criar novo usuário

                    <v-dialog v-model="dialog" activator="parent" width="auto">
                      <div class="create-dialog-box">
                        <div class="title d-flex align-items-center justify-space-between">
                          <h4 class="mb-0">Criar Novo Usuário</h4>
                          <button class="close-btn bg-transparent p-0 border-none elevation-0" @click="dialog = false">
                            <i class="flaticon-close"></i>
                          </button>
                        </div>
                        <div v-if="errorMessage" class="px-6 py-2">
                          <v-alert border="top" type="error" variant="outlined" prominent class="mt-2" closable>
                            {{ errorMessage }}
                          </v-alert>
                        </div>
                        <form @submit.prevent="onCreateUser">
                          <v-row>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Nome do usuário *</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Nome do usuário"
                                  autocomplete="off"
                                  v-model="form.name"
                                  required
                                />
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Email *</label>
                                <input
                                  type="email"
                                  class="input-control"
                                  placeholder="Email"
                                  v-model="form.email"
                                  :pattern="EMAIL_PATTERN"
                                  autocomplete="off"
                                  title="Por favor, insira um e-mail válido."
                                  required
                                />
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Senha *</label>
                                <input
                                  type="password"
                                  class="input-control"
                                  placeholder="Senha"
                                  v-model="form.password"
                                  autocomplete="new-password"
                                  :pattern="PASSWORD_PATTERN"
                                  title="A senha deve ter pelo menos 8 caracteres."
                                  required
                                />
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Confirmar senha *</label>
                                <input
                                  type="password"
                                  class="input-control"
                                  placeholder="Confirmar senha"
                                  v-model="form.confirmPassword"
                                  autocomplete="off"
                                  required
                                  :class="{ 'input-error': passwordsDoNotMatch }"
                                />
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">
                              <div class="form-group">
                                <div class="pingo-form-group without-icon">
                                  <label class="d-block mb-10 fw-semibold gray-color"> Perfil de acesso * </label>
                                  <v-select
                                    label="Selecione um perfil"
                                    :items="['Administrador', 'Engenharia', 'Gerente', 'Cliente']"
                                    v-model="form.role"
                                  ></v-select>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <div class="text-end mt-15">
                            <v-tooltip v-if="!isFormValid" location="top">
                              <template v-slot:activator="{ props }">
                                <button
                                  type="submit"
                                  :class="!isFormValid && 'button-desactive'"
                                  :disabled="!isFormValid"
                                  v-bind="props"
                                >
                                  <i class="ri-add-fill"></i> Criar Novo Usuário
                                </button>
                              </template>
                              <span>Por favor, preencha todos os campos corretamente antes da criação.</span>
                            </v-tooltip>

                            <button
                              v-else
                              type="submit"
                              :class="!isFormValid && 'button-desactive'"
                              :disabled="!isFormValid"
                            >
                              <i class="ri-add-fill"></i> Criar Novo Usuário
                            </button>
                          </div>
                        </form>
                      </div>
                    </v-dialog>
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <d class="gray-color">
                    {{ item.name }}
                  </d>
                </td>
                <td class="gray-color">
                  <a :href="'mailto:' + item.email" class="gray-color">
                    {{ item.email }}
                  </a>
                </td>
                <td class="gray-color">
                  <span class="badge fw-medium green-color" :class="computeClass(item.role || 'Inactive')">
                    {{ item.role || 'Não informado' }}
                  </span>
                </td>
                <td class="gray-color">
                  <d class="gray-color">
                    {{ item.created_at }}
                  </d>
                </td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <button
                        type="button"
                        v-bind="props"
                        class="position-relative dot-btn p-0 bg-transparent border-none"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                    </template>
                    <v-list class="dotMenu two monthMenu">
                      <button
                        type="button"
                        class="bg-transparent border-none position-relative"
                        @click="redirectToEdit(item.id)"
                        v-if="canAccess(userRole, 'edit-users')"
                      >
                        <i class="ri-edit-line"></i> Editar
                      </button>
                      <button
                        type="button"
                        class="bg-transparent border-none position-relative"
                        @click="onDeleteUser(item.id)"
                        v-if="canAccess(userRole, 'remove-users')"
                      >
                        <i class="ri-delete-bin-line"></i> Remover
                      </button>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template v-slot:bottom>
              <div class="footer-table">
                <div class="text-gray-color">
                  <span>
                    Total de <strong>{{ isFiltered ? filteredItemsCount : totalItems }}</strong> resultados
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-end pt-2">
                    <v-pagination
                      v-model="currentPage"
                      :length="totalPages"
                      next-icon="mdi-chevron-right"
                      prev-icon="mdi-chevron-left"
                      :total-visible="TABLE_PAGINATION_ITEMS"
                      @update:model-value="onPageChange"
                      color="#7a7e87"
                    ></v-pagination>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <span class="text-gray-color">Mostrar</span>
                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 20, 50]"
                    label="Linhas por página"
                    class="items-per-page"
                    density="compact"
                    variant="underlined"
                    hide-details
                    single-line
                    @update:model-value="onItemsPerPageChange"
                  ></v-select>
                  <span class="text-gray-color">por página</span>
                </div>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { getUsers, createUser, deleteUser } from '@/services/users';
import { User } from '@/utils/types';
import { formatDate } from '@/utils/format';
import { PASSWORD_PATTERN, EMAIL_PATTERN } from '@/utils/regex';
import { useRouter } from 'vue-router';
import DataTable from '@/components/common/DataTable.vue';
import { TABLE_PAGINATION_ITEMS } from '@/utils/constants';

import { getUserRole } from '../../utils/store';
import { canAccess } from '../../utils/methods';

const ALIGN_CENTER = 'center' as const;

export default defineComponent({
  name: 'UsersList',
  components: {
    DataTable,
  },
  mounted() {
    document.title = 'Gerenciar usuários';
  },
  data() {
    const userRole = getUserRole();
    const showActions = canAccess(userRole, 'edit-users') || canAccess(userRole, 'remove-users');

    return {
      canAccess,
      userRole,
      headers: [
        {
          align: ALIGN_CENTER,
          title: 'Nome',
          key: 'name',
          sortable: true,
          class: 'fw-semibold gray-color text-start',
        },
        { align: ALIGN_CENTER, title: 'Email', key: 'email', sortable: true, class: 'fw-semibold gray-color' },
        {
          align: ALIGN_CENTER,
          title: 'Perfil de acesso',
          key: 'role',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Data de criação',
          key: 'created_at',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        ...(showActions
          ? [
              {
                align: ALIGN_CENTER,
                title: 'Ações',
                key: 'actions',
                sortable: false,
                class: 'fw-semibold gray-color',
              },
            ]
          : []),
      ],
      TABLE_PAGINATION_ITEMS,
    };
  },
  setup() {
    const router = useRouter();
    const users = ref<User[]>([]);
    const dialog = ref(false);
    const value = ref(false);
    const form = ref({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: null,
    });
    const searchQuery = ref('');
    const wordActive = ref('Active');
    const wordInactive = ref('Inactive');
    const isLoading = ref(false);
    const noResults = ref(false);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const totalItems = ref(0);
    const filteredItemsCount = ref(0);
    const totalPages = ref(0);
    const selectedUsers = ref<number[]>([]);
    const errorMessage = ref<string>('');

    const orderBy = ref('');
    const dir = ref('');

    const loadUsers = async (search = '', page = 1, itemsPerPage = 10) => {
      isLoading.value = true;
      try {
        const response = await getUsers({
          search: search,
          orderBy: orderBy.value,
          dir: dir.value,
          itemsPerPage: itemsPerPage,
          page: page,
        });
        users.value = response.filteredRecord.map((user) => ({
          ...user,
          created_at: formatDate(user.created_at),
        }));
        totalItems.value = response.allRecordsCount;
        totalPages.value = response.totalPages;
        filteredItemsCount.value = response.allRecordsCountFiltered;
      } catch (error) {
        console.error('Erro ao carregar usuários:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const onSearchInput = () => {
      loadUsers(searchQuery.value);
    };

    const computeClass = (classValue: string) => {
      return {
        'active-class': wordActive.value === classValue,
        'inactive-class': wordInactive.value === classValue,
      };
    };

    const passwordsDoNotMatch = computed(() => {
      return form.value.password !== form.value.confirmPassword;
    });

    const isFormValid = computed(() => {
      return (
        form.value.name && form.value.email && form.value.password && form.value.role && !passwordsDoNotMatch.value
      );
    });

    const clearErrorMessage = () => {
      errorMessage.value = '';
    };

    const showErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(clearErrorMessage, 4000);
    };

    const onCreateUser = () => {
      if (!isFormValid.value) {
        return;
      }

      clearErrorMessage();

      const data = {
        name: form.value.name,
        email: form.value.email,
        password: form.value.password,
        c_password: form.value.confirmPassword,
        role: form.value.role,
      };

      createUser(data)
        .then(() => {
          dialog.value = false;
          form.value = {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: null,
          };
          loadUsers();
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          showErrorMessage(errorMessage);
        });
    };

    const onDeleteUser = (userId: number) => {
      deleteUser(userId).then(() => {
        loadUsers();
      });
    };

    const redirectToEdit = (userId: number) => {
      router.push({ name: 'UserEdit', params: { id: userId } });
    };

    onMounted(() => {
      loadUsers();
    });

    const onPageChange = (page: number) => {
      currentPage.value = page;
      loadUsers(searchQuery.value, currentPage.value, itemsPerPage.value);
    };

    const onItemsPerPageChange = (items: number) => {
      itemsPerPage.value = items;
      currentPage.value = 1;
      loadUsers(searchQuery.value, currentPage.value, itemsPerPage.value);
    };

    const isFiltered = computed(() => {
      return searchQuery.value.trim() !== '' || filteredItemsCount.value !== totalItems.value;
    });

    const handleOptionsChange = (options) => {
      const hasSortByValue = options.sortBy.length > 0;

      if (!options) return;

      const orderByValue = options.sortBy[0];

      orderBy.value = hasSortByValue ? orderByValue.key : '';
      dir.value = hasSortByValue ? orderByValue.order : '';
      loadUsers(searchQuery.value, currentPage.value, itemsPerPage.value);
    };
    return {
      users,
      dialog,
      value,
      computeClass,
      onCreateUser,
      form,
      passwordsDoNotMatch,
      isFormValid,
      PASSWORD_PATTERN,
      EMAIL_PATTERN,
      redirectToEdit,
      onDeleteUser,
      onSearchInput,
      searchQuery,
      isLoading,
      noResults,
      currentPage,
      itemsPerPage,
      onPageChange,
      onItemsPerPageChange,
      totalItems,
      totalPages,
      selectedUsers,
      isFiltered,
      filteredItemsCount,
      handleOptionsChange,
      errorMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-col-md-6,
.v-col-lg-6,
.v-col-12 {
  padding: 0 12px;
}

.green-color {
  color: #1e9b52 !important;
  background-color: #017b3529 !important;
}

.inactive-class {
  color: #ee368c !important;
  background-color: #ee368c0f !important;
}

.button-desactive {
  cursor: not-allowed;
  background-color: #adadad;

  &:hover {
    background-color: #adadad;
  }
}

.form-search-table {
  width: 40% !important;
  margin: 17px 22px 17px 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;

  form {
    width: 100%;
    border-radius: 6px;
  }
}

.pingo-search-box .input-search {
  border: 1px solid #ededee !important;
  background-color: #f9f9f9;
}

.pingo-search-box button {
  color: #d1d1d1 !important;
}

.button-create {
  height: 49px !important;
  width: 222px !important;

  .ri-add-fill {
    font-size: 18px !important;
  }

  .ri-add-fill:before {
    position: relative !important;
    left: 3px !important;
  }
}

.footer-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;

  .items-per-page {
    border: 1px solid #ededee;
    border-radius: 10px;
    padding: 6px 4px;
    width: 76px;
    margin: 10px;
  }
}

::v-deep {
  .text-error {
    color: rgb(223 80 106) !important;
  }

  .v-alert {
    border-radius: 10px !important;
  }

  .v-alert__content {
    font-size: 13px !important;
  }

  .mdi-close-circle {
    font-size: 36px !important;
  }

  .v-field__input {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .v-field__append-inner {
    padding-top: 5px !important;
  }

  .mdi-menu-right::before {
    color: #777777;
  }

  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .v-btn {
    border-radius: 6px !important;
  }

  .v-btn__content {
    text-transform: none !important;
  }
}

.title-text {
  margin-right: 25px;
  color: var(--blackColor);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);

  span {
    color: #c3c3c3;
  }
}

.v-card.pingo-card {
  padding-top: 5px !important;
}

.text-gray-color {
  color: #777777;
}
</style>
