import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_SettingField = _resolveComponent("SettingField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_DnpField = _resolveComponent("DnpField")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      (_ctx.hasCustomSettings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "fw-semibold" }, "Configurações customizadas", -1)),
            _createVNode(_component_v_divider, { class: "mb-6" }),
            _createVNode(_component_v_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "12",
                      class: "pt-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SettingField, {
                          settings: _ctx.settings,
                          formFields: _ctx.getCustomSettings(),
                          "onUpdate:setting": _ctx.updateSetting,
                          class: "mb-6"
                        }, null, 8, ["settings", "formFields", "onUpdate:setting"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasFieldsDnp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_v_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsDnp, (fields, index) => {
                      return (_openBlock(), _createBlock(_component_v_col, {
                        key: 'dnp-' + index,
                        cols: "12",
                        md: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DnpField, {
                            "onUpdate:config": _ctx.handleConfigUpdate,
                            fields: fields,
                            config: _ctx.getSettingsDnp(fields.variavel)
                          }, null, 8, ["onUpdate:config", "fields", "config"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}