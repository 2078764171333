<template>
  <v-card class="mb-25 pingo-card coming-soon-card">
    <div class="pingo-table-content d-flex flex-column align-items-center">
      <img
        src="../../assets/img/coming-soon-image.png"
        class="d-block mb-25 d-flex align-items-center"
        alt="coming-soon-image"
      />
      <h4 class="mb-15">Ops! Estamos quase lá... 🚧</h4>
      <p class="ms-auto me-auto">
        Essa página ainda está sendo preparada para você. Logo, ela estará disponível com todas as funcionalidades.
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ComingSoonView',
};
</script>

<style lang="scss" scoped>
.coming-soon-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;

  img {
    width: 30%;
    height: 30%;
    padding: 2.7rem 0;
  }
}
</style>
