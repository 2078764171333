<template>
  <div class="pingo-form-group without-icon d-flex flex-wrap gap-2 align-items-center w-100">
    <div v-for="(value, index) in formFields" :key="index" class="d-flex gap-2 align-items-center w-25">
      <v-text-field
        v-if="!value?.options"
        :label="value?.required ? value?.name + ' *' : value?.name"
        :type="getType(value?.type)"
        v-model="localValues[index]"
        :required="value?.required"
        @change="handleChange"
        class="w-100 map-content"
      ></v-text-field>
      <v-select
        v-else
        :label="value?.required ? value?.name + ' *' : value?.name"
        v-model="localValues[index]"
        :items="value?.options"
        @update:menu="handleChange"
        class="w-100 map-content"
      ></v-select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'SettingField',
  props: {
    formFields: {
      type: Object as PropType<any>,
      required: true,
    },
    settings: {
      type: Array as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const defaultSetting = ref(props?.formFields);
    const customSetting = ref(props?.settings?.custom_settings);

    if (customSetting.value) {
      defaultSetting.value.forEach((item: any) => {
        Object.entries(customSetting.value).forEach(([key, setting]: [string, any]) => {
          if (item.name === key) {
            item.default = setting;
          }
        });
      });
    }

    const localValues = ref(props.formFields.map((field: any) => field.default));

    const currentName = computed(() => props.settings.name || '');
    const currentOptions = computed(() => props?.settings?.options || props?.formFields?.options);
    const isRequired = computed(() => props?.settings?.required === 'true');

    const getType = (type: string) => {
      if (type === 'inteiro' || type === 'float') return 'number';
      return 'text';
    };

    onMounted(() => {
      props.formFields.forEach((field: any) => {
        const fieldName = field.name;
        const defaultValue = field.default;
        if (fieldName) {
          store.dispatch('updateCustomSettings', { ...props.settings, name: fieldName, default: defaultValue });
        }
      });
    });

    const pendingChanges = ref({});

    watch(
      localValues,
      (newValues) => {
        newValues.forEach((value, index) => {
          const fieldName = props.formFields[index].name;

          if (fieldName) {
            pendingChanges.value[fieldName] = value;
          }
        });
      },
      { deep: true }
    );

    const handleChange = () => {
      Object.entries(pendingChanges.value).forEach(([name, value]) => {
        store.dispatch('updateCustomSettings', { ...props.settings, name, default: value });
      });
      pendingChanges.value = {};
    };

    return { currentName, currentOptions, localValues, getType, isRequired, handleChange };
  },
});
</script>

<style scoped lang="scss">
.map-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-right: 10px;
  padding-top: 20px;
  gap: 10px;
}

.w-25 {
  width: 25%;
}
</style>
