import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { pt } from 'vuetify/locale';

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#D12225',
          secondary: '#D12225',
        },
      },
    },
  },
  locale: {
    locale: 'pt',
    messages: { pt },
  },
});
