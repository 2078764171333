<template>
  <PageTitle pageTitle="Editar Dispositivo" mainTitle="Editar Dispositivo" />
  <v-card class="tabs-card">
    <v-tabs v-model="tab" align-tabs="start">
      <v-tab :value="TAB_INFO">Informações</v-tab>
      <v-tab :value="DEVICE_CONFIGURATION_TAB">Configurações</v-tab>
    </v-tabs>
  </v-card>

  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card class="mb-30 pingo-card">
        <div v-if="loadingData" class="d-flex justify-center align-items-center min-vh-full">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </div>
        <v-tabs-window v-else v-model="tab">
          <v-tabs-window-item :value="TAB_INFO">
            <v-container fluid>
              <form @submit.prevent="onSubmit" class="form">
                <v-row>
                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Dispositivo *</label>
                      <v-text-field
                        type="text"
                        placeholder="Número do dispositivo"
                        v-model="device.device_uuid"
                        autocomplete="off"
                        required
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Tecnologia *</label>
                      <v-select
                        label="Selecione a tecnologia"
                        :items="EQUIPMENT_TECNOLOGIES"
                        v-model="device.technology"
                        required
                      ></v-select>
                    </div>
                  </v-col>

                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Status *</label>
                      <v-select
                        label="Selecione o status"
                        :items="['Ativo', 'Inativo']"
                        v-model="device.status"
                        required
                      ></v-select>
                    </div>
                  </v-col>

                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Tipo de Dispositivo *</label>
                      <v-select
                        label="Selecione o tipo de dispositivo"
                        :items="devicesTypes"
                        v-model="device.type_id"
                        required
                      ></v-select>
                    </div>
                  </v-col>

                  <div class="col-lg-4 col-md-4 col-sm-4 mt-12 location-input">
                    <GeoDialog v-model="device.location" />
                  </div>

                  <v-col class="col-lg-4 col-md-4 col-sm-4">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Número da Linha</label>
                      <v-text-field
                        type="text"
                        class="input-control"
                        placeholder="Número da Linha"
                        v-model="device.line_number"
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-4 col-md-4 col-sm-4">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Número Operacional</label>
                      <v-text-field
                        type="text"
                        class="input-control"
                        placeholder="Número Operacional"
                        v-model="device.operational_number"
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Nivel de Bateria</label>
                      <v-text-field
                        type="text"
                        class="input-control"
                        placeholder="Nivel de Bateria"
                        v-model="device.power"
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-3 col-md-3 col-sm-3">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-10 fw-semibold gray-color">Temperatura</label>
                      <v-text-field
                        type="text"
                        class="input-control"
                        placeholder="Temperatura"
                        v-model="device.temperature"
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-6 col-md-6 col-sm-6">
                    <div class="multi-select-input">
                      <label class="d-block mb-10 fw-semibold gray-color">Cliente</label>
                      <MultiSelect
                        v-model="device.client_id"
                        :itemsList="clients"
                        :itemValue="'client_id'"
                        :itemTitle="'client_name'"
                        :id="'client'"
                        :label="'Cliente'"
                        :placeholder="'Selecione um cliente'"
                        :noDataText="'Nenhum cliente encontrado'"
                        :multiple="false"
                        :currentPage="currentClientPage"
                        :totalPages="totalClientPages"
                        @update:search="onSearch"
                        @load-more-items="loadMoreClients"
                      />
                    </div>
                  </v-col>

                  <v-col class="col-lg-12 col-md-12 col-sm-12">
                    <div class="pingo-form-group without-icon">
                      <label class="d-block mb-12 fw-semibold gray-color"> Observações </label>
                      <div class="form-control">
                        <textarea class="input-control text-area text-area" v-model="device.observations" />
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-snackbar v-model="isSnackbarVisible" :timeout="3000" top right>
                  Configurações salvas com sucesso!
                </v-snackbar>

                <div class="text-end mt-30">
                  <button
                    type="button"
                    @click="handleBackClick"
                    class="default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                  >
                    Voltar
                  </button>
                  <button
                    type="submit"
                    :loading="loading"
                    :class="{ 'disabled-btn': !isModified }"
                    class="default-btn ps-30 pe-30"
                  >
                    Salvar alterações
                  </button>
                </div>
              </form>
            </v-container>
          </v-tabs-window-item>
          <v-tabs-window-item :value="DEVICE_CONFIGURATION_TAB">
            <div v-if="deviceConfigurationFields || deviceConfiguration">
              <DeviceConfiguration
                @update:setting="handleDeviceCustomConfigUpdate"
                @update:deviceConfiguration="handleDeviceDnpConfigUpdate"
                :fields="deviceConfigurationFields"
                :settings="deviceConfiguration"
              />
              <div class="text-end mt-30">
                <button type="button" @click="handleBackClick" class="default-btn elevation-0 ps-30 pe-30 bg-grey mr-2">
                  Voltar
                </button>
                <button
                  type="submit"
                  :loading="loadingSettings"
                  class="default-btn ps-30 pe-30"
                  @click="saveDeviceConfig"
                >
                  <template v-if="loadingSettings">
                    <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
                  </template>
                  <template v-else> Salvar alterações </template>
                </button>
              </div>
            </div>
            <div class="d-flex justify-center align-items-center min-vh-full" v-else>
              <v-progress-linear color="grey" indeterminate></v-progress-linear>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-col>
  </v-row>
  <ConfirmationDialog
    v-model:visible="confirmDialog"
    title="Confirmação"
    message="Existem alterações não salvas. Tem certeza de que deseja deseja voltar?"
    @confirm="confirmBack"
  />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import PageTitle from '../../components/common/PageTitle.vue';
import { useRoute } from 'vue-router';
import { ConfigurationFieldData, Device, DevicesSettingsData, DeviceType } from '@/utils/types';
import { useRouter } from 'vue-router';
import {
  createDeviceConfig,
  getDevice,
  getDeviceConfig,
  getDeviceConfigFields,
  getDevicesType,
  updateDevice,
  updateDeviceConfig,
} from '@/services/devices';
import { EQUIPMENT_TECNOLOGIES, EQUIPMENT_TYPES } from '@/utils/constants';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import MultiSelect from '@/components/common/MultiSelect.vue';
import { useClients } from '@/utils/composable/useClients';
import GeoDialog from '@/views/devices/component/GeoDialog.vue';
import DeviceConfiguration from '@/views/devices/component/DeviceConfiguration.vue';

import { DEVICE_CONFIGURATION_TAB, TAB_INFO } from '@/utils/constants';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'DeviceEdit',
  components: {
    PageTitle,
    ConfirmationDialog,
    GeoDialog,
    DeviceConfiguration,
    MultiSelect,
  },
  data() {
    return {
      tab: null,
      DEVICE_CONFIGURATION_TAB,
      TAB_INFO,
    };
  },
  mounted() {
    document.title = 'Editar dispositivo';
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const deviceId = computed(() => Number(route.params.id));
    const router = useRouter();
    const loading = ref(false);
    const loadingDevice = ref(false);
    const devicesTypes = ref<string[]>([]);
    const device = ref<Device>({
      id: 0,
      device_uuid: '',
      technology: '',
      status: '',
      power: 0,
      temperature: 0,
      observations: '',
      line_number: '',
      operational_number: '',
      location: {
        latitude: null,
        longitude: null,
      },
      type_id: '',
      client_id: 0,
      client_name: null,
      created_at: '',
      updated_at: '',
      deleted_at: '',
    });

    const deviceConfigurationFields = ref<ConfigurationFieldData>();
    const initialDeviceConfiguration = ref<DevicesSettingsData | null>(null);

    const deviceConfiguration = ref<any>(null);
    const updatedDnpSettings = ref<any>(null);
    const updateCustomSettings = ref<any>(null);

    const initialDevice = ref<Device | null>(null);
    const isModified = ref(false);
    const confirmDialog = ref(false);

    const loadingSettings = ref(false);
    const isModifiedSettings = ref(false);

    const isSnackbarVisible = ref(false);

    const loadingData = ref(true);

    const loadDevices = async () => {
      try {
        loadingDevice.value = true;
        const data = await getDevice(deviceId.value);
        const equipmentName = Object.entries(EQUIPMENT_TYPES).find(
          ([value]) => EQUIPMENT_TYPES[value] === data.device.type_id
        );

        const newData = {
          ...data.device,
          status: data.device.status ? 'Ativo' : 'Inativo',
          type_id: data.device.type_id !== null ? equipmentName?.[0] : '',
          location: {
            latitude: data.device.latitude ?? null,
            longitude: data.device.longitude ?? null,
          },
          client_id: data.device.client_name
            ? [
                {
                  client_id: data.device.client_id,
                  client_name: data.device.client_name,
                },
              ]
            : null,
        };

        device.value = newData;
        initialDevice.value = JSON.parse(JSON.stringify(newData));
      } catch (error) {
        console.error('Erro ao carregar dispositivo:', error);
      } finally {
        setTimeout(() => {
          loadingDevice.value = false;
        }, 1000);
      }
    };

    const loadDeviceTypes = async () => {
      try {
        const { types } = await getDevicesType();
        devicesTypes.value = types.map((type: DeviceType) => type.name);
      } catch (error) {
        console.error('Erro ao carregar tipos de dispositivos:', error);
      }
    };

    const loadDeviceConfigFields = async () => {
      try {
        const deviceType = device.value?.device_type?.name;

        if (!deviceType) {
          console.warn('Tipo de dispositivo não encontrado.');
          return;
        }

        const data = await getDeviceConfigFields(deviceType);
        deviceConfigurationFields.value = data;
      } catch (error) {
        console.error('Erro ao carregar os campos de configuração do dispositivo:', error);
      }
    };

    const loadDeviceConfig = async () => {
      try {
        const deviceId = device.value?.id;
        const data = await getDeviceConfig(deviceId);
        deviceConfiguration.value = data;
        initialDeviceConfiguration.value = JSON.parse(JSON.stringify(data));
      } catch (error) {
        deviceConfiguration.value = null;
        console.error('Erro ao carregar configuração do dispositivo:', error);
      }
    };

    const onSubmit = () => {
      loading.value = true;
      const data = device.value;
      const id = deviceId.value;

      const newData = {
        device_uuid: data.device_uuid,
        technology: data.technology,
        status: data.status === 'Ativo' ? 1 : 0,
        power: data.power,
        temperature: data.temperature,
        observations: data.observations,
        line_number: data.line_number,
        operational_number: data.operational_number,
        type_id: data.type_id !== null ? EQUIPMENT_TYPES[data.type_id] : 0,
        client_id: data.client_id,
        latitude: data.location.latitude,
        longitude: data.location.longitude,
      };

      updateDevice(id, newData).then(() => {
        loading.value = false;
        router.push({ name: 'DevicesView' });
      });
    };

    onMounted(async () => {
      loadingData.value = true;
      await loadDevices();
      await loadDeviceTypes();
      await loadDeviceConfigFields();
      await loadDeviceConfig();
      loadingData.value = false;
    });

    watch(
      device,
      (newDevice) => {
        isModified.value = JSON.stringify(newDevice) !== JSON.stringify(initialDevice.value);
      },
      { deep: true }
    );

    watch(
      deviceConfiguration,
      (newConfig) => {
        isModifiedSettings.value = JSON.stringify(newConfig) !== JSON.stringify(initialDeviceConfiguration.value);
      },
      { deep: true }
    );

    const handleBackClick = () => {
      if (isModified.value) {
        confirmDialog.value = true;
      } else {
        backToPrevious();
      }
    };

    const backToPrevious = () => {
      router.go(-1);
    };

    const confirmBack = () => {
      confirmDialog.value = false;
      backToPrevious();
    };

    const handleDeviceCustomConfigUpdate = (updatedConfig: any) => {
      updateCustomSettings.value = updatedConfig;
    };

    const handleDeviceDnpConfigUpdate = (updatedConfig: any) => {
      updatedDnpSettings.value = updatedConfig;
    };

    const saveDeviceConfig = async () => {
      const deviceId = device.value?.id;
      const dnpChanges = computed(() => store.getters.getDnpChanges);
      const customSettings = computed(() => store.getters.getCustomSettings);
      const transformedDnpChanges = Object.values(dnpChanges.value || {});

      const data = {
        custom_settings: customSettings.value,
        dnp_map: transformedDnpChanges,
      };

      loadingSettings.value = true;

      try {
        if (Object.keys(deviceConfiguration.value).length === 0) {
          await createDeviceConfig(data, deviceId);
        } else {
          await updateDeviceConfig(data, deviceId);
        }
        isSnackbarVisible.value = true;
      } catch (error) {
        console.error('Erro ao salvar configuração do dispositivo:', error);
      } finally {
        loadingSettings.value = false;
      }
    };

    const { clients, loadMoreClients, isClientsLoading, totalClientPages, currentClientPage, fetchClients, onSearch } =
      useClients();

    return {
      onSubmit,
      loading,
      device,
      devicesTypes,
      EQUIPMENT_TECNOLOGIES,
      isModified,
      backToPrevious,
      handleBackClick,
      confirmDialog,
      confirmBack,
      clients,
      deviceConfigurationFields,
      deviceConfiguration,
      saveDeviceConfig,
      loadingSettings,
      isModifiedSettings,
      handleDeviceCustomConfigUpdate,
      handleDeviceDnpConfigUpdate,
      isSnackbarVisible,
      loadingData,
      loadMoreClients,
      isClientsLoading,
      totalClientPages,
      currentClientPage,
      fetchClients,
      onSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .location-input {
    .v-input {
      outline: 0;
      width: 100%;
      height: 58px;
      display: block;
      box-shadow: unset;
      border-radius: 10px;
      color: var(--blackColor);
      font-size: var(--fontSize);
      border: 1px solid #b1b5c3;
      transition: var(--transition);
      padding-left: 20px;
      padding-right: 20px;
    }

    .form-group {
      margin-left: 10px;
    }

    .v-field__append-inner {
      right: 230px;
      top: 1px;
    }
  }

  .v-tab__slider {
    display: none !important;
  }

  .v-tab.v-tab.v-btn {
    span {
      font-weight: 400 !important;
      color: #a2a3a9;
      font-size: 14px !important;
      font-family: var(--poppinsFontFamily);
    }
  }

  .v-tab-item--selected {
    color: #35ac67 !important;
    background-color: #35ab6a;

    span {
      color: #fff !important;
    }
  }

  .v-field__outline__notch {
    &::before {
      display: inline-block;
    }

    &::after {
      display: inline-block;
    }
  }

  .v-autocomplete .v-field .v-text-field__prefix,
  .v-autocomplete .v-field .v-text-field__suffix,
  .v-autocomplete .v-field .v-field__input,
  .v-autocomplete .v-field.v-field {
    padding: 5px 8px !important;
  }
}

.text-area {
  padding: 10px;
  color: #43444b !important;
  resize: none;

  &:focus,
  &:focus-visible {
    border-color: none !important;
    outline: none;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 48px;
    padding: 0px 25px;
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}

.tabs-card {
  padding: 8px !important;
  margin-bottom: 14px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07) !important;
}
</style>
