import { createStore } from 'vuex';
import {
  setToken,
  getToken,
  removeToken,
  getFirstAccessStatus,
  setFirstAccessStatus,
  removeFirstAccessStatus,
} from '../utils/cookieUtils';
import { SETTING_FIELDS } from '@/utils/constants';

export default createStore({
  state: {
    auth: {
      token: getToken() || null,
    },
    dnpChanges: {},
    customSettings: {},
    isFirstAccess: getFirstAccessStatus() || null,
  },
  getters: {
    isAuthenticated(state): boolean {
      return !!state.auth.token;
    },
    getToken(state): string | null {
      return state.auth.token;
    },
    getFirstAccessStatus(state): string | null {
      return state.isFirstAccess;
    },
    getDnpChanges: (state) => state.dnpChanges,
    getCustomSettings: (state) => state.customSettings,
  },
  mutations: {
    setToken(state, token: string) {
      state.auth.token = token;
      setToken(token);
    },
    clearToken(state) {
      state.auth.token = null;
      removeToken();
    },
    setFirstAccessStatus(state, status: string) {
      state.isFirstAccess = status;
      setFirstAccessStatus(status);
    },
    clearFirstAccessStatus(state) {
      state.isFirstAccess = null;
      removeFirstAccessStatus();
    },
    updateDnpChange(state, { variavel, enderecoDNP, tagDNP, valor }) {
      const dnpChange = state.dnpChanges[variavel] || {
        [SETTING_FIELDS.VARIABLE]: variavel || '',
        [SETTING_FIELDS.DNP_ADDRESS]: '',
        [SETTING_FIELDS.DNP_TAG]: '',
        [SETTING_FIELDS.VALUE]: 0,
      };

      if (enderecoDNP !== undefined) {
        dnpChange[SETTING_FIELDS.DNP_ADDRESS] = enderecoDNP;
      }
      if (tagDNP !== undefined) {
        dnpChange[SETTING_FIELDS.DNP_TAG] = tagDNP;
      }
      if (valor !== undefined) {
        dnpChange[SETTING_FIELDS.VALUE] = valor;
      }

      state.dnpChanges[variavel] = dnpChange;
    },
    updateCustomSettings(state, payload) {
      state.customSettings = {
        ...state.customSettings,
        [payload.name]: payload.default,
      };
    },
  },
  actions: {
    firstAccess({ commit }, status: string) {
      commit('setFirstAccessStatus', status);
    },
    login({ commit }, token: string) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearToken');
      commit('clearFirstAccessStatus');
    },
    updateDnpChange({ commit }, payload) {
      commit('updateDnpChange', payload);
    },
    updateCustomSettings({ commit }, payload) {
      commit('updateCustomSettings', payload);
    },
  },
  modules: {},
});
