import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-6"
}
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "my-6" }
const _hoisted_4 = {
  key: 0,
  class: "mb-0 ms-2 text-danger text-xs"
}
const _hoisted_5 = { class: "my-6" }
const _hoisted_6 = { class: "my-6" }
const _hoisted_7 = { class: "my-6" }
const _hoisted_8 = { class: "my-6" }
const _hoisted_9 = { class: "my-6" }
const _hoisted_10 = { class: "my-6" }
const _hoisted_11 = { class: "w-100 pb-3" }
const _hoisted_12 = { class: "text-end d-flex align-center justify-end" }
const _hoisted_13 = { class: "pingo-table-content" }
const _hoisted_14 = { class: "pingo-table v-elevation-z8" }
const _hoisted_15 = { class: "pingo-table-content" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "d-flex align-items-center justify-content-space-between mt-10 mb-4" }
const _hoisted_18 = { class: "d-flex align-items-center form-search-table" }
const _hoisted_19 = { class: "d-flex align-items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_date_input = _resolveComponent("v-date-input")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_VForm = _resolveComponent("VForm")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Relatórios",
      mainTitle: "Relatórios"
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "mb-30 pingo-card" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                    _createElementVNode("h5", { class: "mb-0" }, "Selecione o dispositivo")
                  ], -1)),
                  _createElementVNode("form", null, [
                    _createVNode(_component_v_autocomplete, {
                      modelValue: $setup.deviceType,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.deviceType) = $event)),
                      items: ['Cigarra', 'Cigarra Plus', 'Smart Grid Sensor', '7Trafo'],
                      class: "example-full-width",
                      label: "Dispositivo",
                      variant: "outlined",
                      density: "comfortable",
                      rounded: "lg",
                      focused: false
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                ($setup.deviceType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_expansion_panels, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_expansion_panel, null, {
                            title: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _cache[12] || (_cache[12] = _createElementVNode("span", null, "Filtros", -1)),
                                ($setup.totalAppliedFilters > 0)
                                  ? (_openBlock(), _createBlock(_component_v_badge, {
                                      key: 0,
                                      content: $setup.totalAppliedFilters,
                                      color: "white",
                                      inline: ""
                                    }, null, 8, ["content"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_VForm, { class: "w-100" }, {
                                    default: _withCtx(() => [
                                      ($setup.devicesList.length === 0 && $setup.clients.length === 0)
                                        ? (_openBlock(), _createBlock(_component_v_row, {
                                            key: 0,
                                            class: "w-100 d-flex align-center justify-center",
                                            cols: "12",
                                            lg: "6",
                                            md: "6",
                                            fluid: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_progress_circular, {
                                                indeterminate: "",
                                                color: "grey",
                                                class: "my-6"
                                              })
                                            ]),
                                            _: 1
                                          }))
                                        : (_openBlock(), _createBlock(_component_v_row, {
                                            key: 1,
                                            class: "w-100"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, {
                                                cols: "12",
                                                lg: "12",
                                                md: "12",
                                                fluid: "",
                                                class: "d-flex align-start justify-center flex-wrap p-0"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6",
                                                    fluid: ""
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_3, [
                                                        _createVNode(_component_v_date_input, {
                                                          modelValue: $setup.filters.date,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filters.date) = $event)),
                                                          placeholder: "Data",
                                                          id: "date",
                                                          density: "comfortable",
                                                          label: "Data",
                                                          multiple: "range",
                                                          clearable: "",
                                                          "prepend-icon": "",
                                                          variant: "outlined",
                                                          "bg-color": "white",
                                                          color: "#35ac67",
                                                          rounded: "lg",
                                                          theme: "primary",
                                                          "show-adjacent-months": "",
                                                          keyboardDateTime: "",
                                                          "persistent-counter": "",
                                                          "ok-text": "Aplicar",
                                                          "cancel-text": "Cancelar",
                                                          "error-messages": $setup.dateErrorMessage,
                                                          "onClick:clear": $setup.handleClearDate
                                                        }, null, 8, ["modelValue", "error-messages", "onClick:clear"]),
                                                        ($setup.dateErrorMessage)
                                                          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.dateErrorMessage), 1))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _createElementVNode("div", _hoisted_5, [
                                                        _createVNode(_component_MultiSelect, {
                                                          modelValue: $setup.filters.devicesId,
                                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.filters.devicesId) = $event)),
                                                          itemsList: $setup.devicesList,
                                                          itemValue: 'device_id',
                                                          itemTitle: 'device_uuid',
                                                          id: 'devices',
                                                          label: 'Dispositivos',
                                                          placeholder: 'Dispositivos',
                                                          noDataText: 'Nenhum resultado encontrado!',
                                                          currentPage: $setup.currentDevicePage,
                                                          totalPages: $setup.totalDevicePages,
                                                          "onUpdate:search": $setup.onDeviceSearch,
                                                          onLoadMoreItems: $setup.loadMoreDevices
                                                        }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                                                      ]),
                                                      _createElementVNode("div", _hoisted_6, [
                                                        _createVNode(_component_v_text_field, {
                                                          modelValue: $setup.filters.lineNumber,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.filters.lineNumber) = $event)),
                                                          density: "comfortable",
                                                          label: "Número da Linha",
                                                          id: "lineNumber",
                                                          placeholder: "Número da Linha",
                                                          clearable: "",
                                                          variant: "outlined",
                                                          "bg-color": "white",
                                                          color: "#35ac67",
                                                          rounded: "lg",
                                                          theme: "primary"
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _createElementVNode("div", _hoisted_7, [
                                                        _createVNode(_component_v_autocomplete, {
                                                          items: $data.STATUS,
                                                          modelValue: $setup.filters.status,
                                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.filters.status) = $event)),
                                                          "item-props": true,
                                                          "item-value": "value",
                                                          "item-title": "name",
                                                          label: "Status",
                                                          id: "status",
                                                          density: "comfortable",
                                                          placeholder: "Status",
                                                          multiple: "",
                                                          chips: "",
                                                          "closable-chips": "",
                                                          clearable: "",
                                                          variant: "outlined",
                                                          "bg-color": "white",
                                                          color: "#35ac67",
                                                          rounded: "lg",
                                                          theme: "primary"
                                                        }, null, 8, ["items", "modelValue"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    lg: "6",
                                                    md: "6",
                                                    fluid: ""
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_8, [
                                                        _createVNode(_component_MultiSelect, {
                                                          modelValue: $setup.filters.clients,
                                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.filters.clients) = $event)),
                                                          itemsList: $setup.clients,
                                                          itemValue: 'client_id',
                                                          itemTitle: 'client_name',
                                                          id: 'client',
                                                          label: 'Clientes',
                                                          placeholder: 'Selecione os clientes',
                                                          noDataText: 'Nenhum cliente encontrado',
                                                          currentPage: $setup.currentClientPage,
                                                          totalPages: $setup.totalClientPages,
                                                          "onUpdate:search": $setup.onClientSearch,
                                                          onLoadMoreItems: $setup.loadMoreClients
                                                        }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                                                      ]),
                                                      _createElementVNode("div", _hoisted_9, [
                                                        _createVNode(_component_MultiSelect, {
                                                          modelValue: $setup.filters.operationalNumber,
                                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.filters.operationalNumber) = $event)),
                                                          itemsList: $setup.operationalNumberList,
                                                          itemValue: 'operational_number',
                                                          itemTitle: 'operational_number',
                                                          id: 'operationalNumber',
                                                          label: 'Número Operacional',
                                                          placeholder: 'Selecione os números operacionais',
                                                          noDataText: 'Nenhum número operacional encontrado',
                                                          currentPage: $setup.currentOperationalNumberPage,
                                                          totalPages: $setup.totalOperationalNumber,
                                                          "onUpdate:search": $setup.onOperationalNumberSearch,
                                                          onLoadMoreItems: $setup.loadMoreOperationalNumber
                                                        }, null, 8, ["modelValue", "itemsList", "currentPage", "totalPages", "onUpdate:search", "onLoadMoreItems"])
                                                      ]),
                                                      _createElementVNode("div", _hoisted_10, [
                                                        _createVNode(_component_v_autocomplete, {
                                                          modelValue: $setup.filters.technology,
                                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.filters.technology) = $event)),
                                                          items: $data.EQUIPMENT_TECNOLOGIES,
                                                          id: "technology",
                                                          density: "comfortable",
                                                          label: "Tecnologia",
                                                          placeholder: "Tecnologia",
                                                          multiple: "",
                                                          chips: "",
                                                          "closable-chips": "",
                                                          clearable: "",
                                                          variant: "outlined",
                                                          "bg-color": "white",
                                                          color: "#35ac67",
                                                          rounded: "lg",
                                                          theme: "primary"
                                                        }, null, 8, ["modelValue", "items"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _createElementVNode("div", _hoisted_11, [
                                                _createElementVNode("div", _hoisted_12, [
                                                  _createVNode(_component_v_btn, {
                                                    type: "button",
                                                    class: "elevation-0 ps-20 pe-20 mr-2 button elevation-0",
                                                    color: "grey",
                                                    onClick: $setup.clearFilters,
                                                    variant: "text"
                                                  }, {
                                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                      _createTextVNode(" Limpar filtros ")
                                                    ])),
                                                    _: 1
                                                  }, 8, ["onClick"]),
                                                  _createVNode(_component_v_btn, {
                                                    type: "button",
                                                    class: "default-btn ps-30 pe-30 button elevation-0",
                                                    rounded: "lg",
                                                    variant: "tonal",
                                                    loading: $setup.loadingButtonApply,
                                                    disabled: $setup.isFilterButtonDisabled || $setup.loadingButtonApply,
                                                    onClick: $setup.applyFilters
                                                  }, {
                                                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                      _createTextVNode(" Filtrar ")
                                                    ])),
                                                    _: 1
                                                  }, 8, ["loading", "disabled", "onClick"])
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      ($setup.deviceType)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_v_data_table_server, {
                              headers: $data.HEADER_REPORTS[$setup.deviceType],
                              items: $setup.data,
                              "items-length": $setup.totalItems,
                              loading: $setup.loading,
                              page: $setup.currentPage,
                              "items-per-page": $setup.itemsPerPage,
                              "items-per-page-options": [10, 20, 30, 40, 50],
                              "onUpdate:options": $setup.onOptionsChange,
                              "items-per-page-text": "Linhas por página",
                              "loading-text": "Carregando...",
                              "no-data-text": "Nenhum resultado encontrado!",
                              "no-results-text": "Nenhum resultado encontrado!",
                              "show-current-page": ""
                            }, {
                              top: _withCtx(() => [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("form", {
                                      class: "pingo-search-box",
                                      onSubmit: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
                                    }, [
                                      _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "input-search border-radius",
                                        placeholder: "Pesquise aqui...",
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.search) = $event)),
                                        onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($setup.onSearchInput && $setup.onSearchInput(...args))),
                                        density: "comfortable",
                                        rounded: "lg"
                                      }, null, 544), [
                                        [_vModelText, $setup.search]
                                      ]),
                                      _cache[15] || (_cache[15] = _createElementVNode("button", { type: "submit" }, [
                                        _createElementVNode("i", { class: "ri-search-line" })
                                      ], -1))
                                    ], 32)
                                  ]),
                                  _createElementVNode("div", _hoisted_19, [
                                    _createVNode(_component_v_tooltip, {
                                      text: "Exportar dados",
                                      location: "bottom"
                                    }, {
                                      activator: _withCtx(({ props }) => [
                                        _createVNode(_component_v_btn, _mergeProps({
                                          class: "default-btn shadow-none elevation-0 button-create button mr-2",
                                          variant: "tonal",
                                          "append-icon": "ri-upload-2-line"
                                        }, props, {
                                          loading: $setup.loadingExport,
                                          rounded: "lg",
                                          onClick: $setup.onExportReport
                                        }), {
                                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                                            _createElementVNode("i", { class: "ri-upload-2-line" }, null, -1),
                                            _createTextVNode(" Exportar ")
                                          ])),
                                          _: 2
                                        }, 1040, ["loading", "onClick"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_tooltip, {
                                      text: "Atualizar tabela",
                                      location: "bottom"
                                    }, {
                                      activator: _withCtx(({ props }) => [
                                        _createVNode(_component_v_btn, _mergeProps({
                                          class: "default-btn shadow-none elevation-0 button-create button",
                                          color: "grey"
                                        }, props, {
                                          onClick: $setup.onRefreshTable,
                                          rounded: "lg"
                                        }), {
                                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                                            _createElementVNode("i", { class: "ri-restart-line" }, null, -1)
                                          ])),
                                          _: 2
                                        }, 1040, ["onClick"])
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["headers", "items", "items-length", "loading", "page", "items-per-page", "onUpdate:options"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}