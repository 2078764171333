import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon d-flex flex-wrap gap-2 align-items-center w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formFields, (value, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "d-flex gap-2 align-items-center w-25"
      }, [
        (!value?.options)
          ? (_openBlock(), _createBlock(_component_v_text_field, {
              key: 0,
              label: value?.required ? value?.name + ' *' : value?.name,
              type: _ctx.getType(value?.type),
              modelValue: _ctx.localValues[index],
              "onUpdate:modelValue": ($event: any) => ((_ctx.localValues[index]) = $event),
              required: value?.required,
              onChange: _ctx.handleChange,
              class: "w-100 map-content"
            }, null, 8, ["label", "type", "modelValue", "onUpdate:modelValue", "required", "onChange"]))
          : (_openBlock(), _createBlock(_component_v_select, {
              key: 1,
              label: value?.required ? value?.name + ' *' : value?.name,
              modelValue: _ctx.localValues[index],
              "onUpdate:modelValue": ($event: any) => ((_ctx.localValues[index]) = $event),
              items: value?.options,
              "onUpdate:menu": _ctx.handleChange,
              class: "w-100 map-content"
            }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "items", "onUpdate:menu"]))
      ]))
    }), 128))
  ]))
}