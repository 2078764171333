<template>
  <div class="pingo-form-group without-icon dnp-content">
    <div class="w-100 title">
      <h5 class="fw-semibold">{{ label }}</h5>
      <v-divider />
    </div>
    <div class="map-content">
      <div v-if="(valueConfig || valueDNP) && !isBooleanType" class="w-100">
        <v-text-field
          label="Valor"
          @input="(value) => handleValueChange(value.target.value, 'valor')"
          :type="getTypeInput(currentValueType)"
          v-model="localValue"
        ></v-text-field>
      </div>
      <div v-if="tagConfig || tagDNP" class="w-100">
        <v-text-field
          label="Tag DNP"
          @input="(value) => handleValueChange(value.target.value, 'tagDNP')"
          :type="getTypeInput(currentTagType)"
          v-model="localTag"
        ></v-text-field>
      </div>
      <div v-if="enderecoConfig || enderecoDNP" class="w-100">
        <v-text-field
          label="Endereço DNP"
          :type="getTypeInput(currentEnderecoType)"
          v-model="localEndereco"
          @input="(value) => handleValueChange(value.target.value, 'enderecoDNP')"
        ></v-text-field>
      </div>
      <div class="toggle" v-if="isBooleanType">
        <v-btn-toggle v-model="localValue" rounded="0" group @update:model-value="handleValueChange($event)">
          <v-btn :value="1" text="Habilitado" />
          <v-btn :value="0" text="Desabilitado" />
        </v-btn-toggle>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { getTypeInput } from '@/utils/format';
import { useStore } from 'vuex';
import { SETTING_FIELDS } from '@/utils/constants';
import { onBeforeMount } from 'vue';

export default defineComponent({
  name: 'DnpField',
  props: {
    fields: {
      type: Object as PropType<any>,
      required: true,
    },
    config: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  data(props) {
    const store = useStore();
    const localValue = props?.config ? props?.config['valor'] : props?.fields['valor']?.default;
    const localTag = props?.config ? props?.config['Tag DNP'] : props?.fields['Tag DNP']?.default;
    const localEndereco = props?.config ? props?.config['Endereço DNP'] : props?.fields['Endereço DNP']?.default;

    const handleValueChange = (value, type = 'valor') => {
      switch (type) {
        case 'valor':
          this.localValue = value;
          break;
        case 'tagDNP':
          this.localTag = value;
          break;
        case 'enderecoDNP':
          this.localEndereco = value;
          break;
      }

      const payload = {
        variavel: props?.fields?.variavel,
        enderecoDNP: this.localEndereco,
        tagDNP: this.localTag,
        valor: this.localValue,
      };

      store.dispatch('updateDnpChange', payload);
    };

    return {
      localValue,
      localTag,
      localEndereco,
      handleValueChange,
      SETTING_FIELDS,
    };
  },
  computed: {
    label() {
      return this?.fields?.variavel;
    },
    tagDNP() {
      return this?.fields['Tag DNP'] || '';
    },
    enderecoDNP() {
      return this?.fields['Endereço DNP'] || '';
    },
    valueDNP() {
      return this?.fields ? this?.fields['valor'] : '';
    },
    tagConfig() {
      return this?.config ? this?.config['Tag DNP'] : '';
    },
    enderecoConfig() {
      return this?.config ? this?.config['Endereço DNP'] : '';
    },
    valueConfig() {
      return this?.config ? this?.config['valor'] : '';
    },
    isBooleanType() {
      return this?.fields['valor']?.type === 'boolean';
    },
    currentValueType() {
      return (this.config?.valor || this.fields?.valor)?.type;
    },
    currentTagType() {
      return (this.config?.['Tag DNP'] || this.fields?.['Tag DNP'])?.type;
    },
    currentEnderecoType() {
      return (this.config?.['Endereço DNP'] || this.fields?.['Endereço DNP'])?.type;
    },
  },
  setup(props) {
    const store = useStore();
    const isFirstConfig = !props?.config;

    const computedValues = computed(() => {
      const variavel = isFirstConfig ? props?.fields?.variavel : props?.config?.variavel || '';
      const enderecoDNP = isFirstConfig ? props?.fields['Endereço DNP'].default : props?.config['Endereço DNP'];
      const tagDNP = isFirstConfig ? props?.fields['Tag DNP']?.default : props?.config['Tag DNP'];
      const valor = isFirstConfig ? props?.fields['valor'].default : props?.config['valor'];
      return { variavel, enderecoDNP, tagDNP, valor };
    });

    onBeforeMount(() => {
      const { variavel, enderecoDNP, tagDNP, valor } = computedValues?.value;
      store.dispatch('updateDnpChange', {
        variavel,
        enderecoDNP,
        tagDNP,
        valor,
      });
    });
  },

  methods: {
    getTypeInput,
  },
});
</script>

<style scoped lang="scss">
.dnp-content {
  p {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .map-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
  }

  .title {
    margin-bottom: 30px;
  }

  .toggle {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;

    .v-btn {
      background-color: #f5f5f5;
      border: 1px solid #d9d9d9;
      color: #999999;

      &.v-btn--active {
        background-color: #35ab6a;
        color: #fff;
      }
    }

    .v-btn-group--density-default.v-btn-group {
      height: 54px;
    }

    .v-btn-group > button:first-of-type {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }

    .v-btn-group > button:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
