import axios from 'axios';
import store from '@/store';
import { FilterParamsRecord, ReportResponse } from '@/utils/types';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export async function getDeviceMonitoring(params: FilterParamsRecord = {}): Promise<ReportResponse> {
  const data = {
    startDate: params.startDate || '',
    endDate: params.endDate || '',
    deviceType: params.deviceType || '',
    devicesId: params.devicesId || [],
    clients: params.clients || [],
    operationalNumber: params.operationalNumber || [],
    lineNumber: params.lineNumber || '',
    status: params.status || [],
    technology: params.technology || [],
    search: params.search || '',
    orderBy: params.orderBy || '',
    direction: params.direction || '',
    itemsPerPage: params.itemsPerPage || '10',
    page: params.page || '1',
  };

  try {
    const response = await axios.get(`${baseUrl}/api/reports/device-monitoring`, {
      params: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
